
import Header from "../components/common/header";
import Sidebar from "../components/common/sidebar";
import Footer from "../components/common/footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import settingActions from "../redux/settings/actions";
import moment from "moment";

const Settings = () => {

    const dispatch = useDispatch();
    const { settings } = useSelector(state => state.settingReducer);
    const newProject = useRef();
    const lockedDate = useRef();
    const automaticLock = useRef();

    const updateSettings = () => {

        dispatch({
            type: settingActions.UDPATE_SETTINGS, payload: {
                new_project_billable: newProject.current.checked,
                locked_date: lockedDate.current.value,
                automatic_lock_date: automaticLock.current.checked
            }
        })
    }

    useEffect(() => {
        dispatch({ type: settingActions.GET_SETTINGS });
    }, []);

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                    <div className="tab-pane" id="permissions" role="tabpanel">
                                        <h5>Lock Settings</h5>
                                        <div className="my-3">
                                            <div className="form-check form-switch mb-3">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onClick={updateSettings} ref={newProject} defaultChecked={settings?.new_project_billable} />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">New Project Billable</label>
                                            </div>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="date" id="flexSwitchCheckDefault1" onChange={updateSettings} ref={lockedDate} max={moment(new Date()).format('YYYY-MM-DD')} defaultValue={moment(new Date(settings?.locked_date)).format('YYYY-MM-DD')} />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault1">Lock before time({moment(settings?.locked_date).format('YYYY-MM-DD')})</label>
                                            </div>
                                            <div className="form-check form-switch mb-3">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault2" onClick={updateSettings} ref={automaticLock} defaultChecked={settings?.automatic_lock_date} />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault2">Automatic lock update(automatically locks every wednesday)</label>
                                            </div>
                                        </div>
                                        <hr />


                                    </div>
                                </div>
                            </div>

                            <div style={{ clear: "both" }}></div>

                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
            <div className="rightbar-overlay"></div>
        </>
    )
}

export default Settings;
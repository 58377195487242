import actions from "./actions";

const initialState = {
    users: [],
    teams: [],
    roles: [],
    userFilterParams: {
        status: true,
        name: ""
    },
    teamFilterParams: {
        status: true,
        name: ""
    },
    userPagination: {
        entries: 50,
        currentPage: 1
    },
    teamPagination: {
        entries: 50,
        currentPage: 1
    }
};

const teamReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_USERS:
            return {
                ...state,
                users: action.payload
            }
        case actions.SET_ROLES:
            return {
                ...state,
                roles: action.payload
            }
        case actions.SET_TEAMS:
            return {
                ...state,
                teams: action.payload
            }
        case actions.SET_USER_FILTER_PARAMS:
            return {
                ...state,
                userFilterParams: action.payload
            }
        case actions.SET_TEAM_FILTER_PARAMS:
            return {
                ...state,
                teamFilterParams: action.payload
            }
        case actions.SET_USER_PAGINATION:
            return {
                ...state,
                userPagination: action.payload
            }
        case actions.SET_TEAM_PAGINATION:
            return {
                ...state,
                teamPagination: action.payload
            }
        default:
            return state;
    }
};

export default teamReducer;
import {
    takeEvery, call, all,
    put
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'
import commonActions from '../common/actions';
import handleError from '../../utils/errors';
import { store } from '../store';

const categorySaga = function* () {
    yield all([
        yield takeEvery(actions.GET_CATEGORIES, getCategories),
        yield takeEvery(actions.GET_ACTIVE_CATEGORIES, getActiveCategories),
        yield takeEvery(actions.CREATE_CATEGORY, createCategory),
        yield takeEvery(actions.UPDATE_CATEGORY, updateCategory),
        yield takeEvery(actions.DELETE_CATEGORY, deleteCategory)
    ]);
};

const getCategories = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/categories${store.getState().categoryReducer.pagination.currentPage === 1 ? "" : `?page=${store.getState().categoryReducer.pagination.currentPage}`}`, {
                params: {
                    status: store.getState().categoryReducer.filterParams.status,
                    entries: store.getState().categoryReducer.pagination.entries,
                    name: store.getState().categoryReducer.filterParams.name
                }
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));

        yield put({ type: actions.SET_CATEGORIES, payload: result?.data ? result.data.categories : null });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_CATEGORIES, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const getActiveCategories = function* (data) {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/active-categories`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));
        yield put({ type: actions.SET_ACTIVE_CATEGORIES, payload: result?.data ? result.data.categories : null });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_ACTIVE_CATEGORIES, payload: [] });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const createCategory = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(`${API_URL}/categories`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_CATEGORIES });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const updateCategory = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.put(`${API_URL}/categories/${data.payload.id}`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_CATEGORIES });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const deleteCategory = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.delete(`${API_URL}/categories/${data.payload.id}`)
        );
        if (store.getState().categoryReducer.categories && store.getState().categoryReducer?.pagination.currentPage > 1 && store.getState().categoryReducer?.pagination.currentPage === store.getState().categoryReducer?.categories?.last_page) {
            if ((parseInt(store.getState().categoryReducer.pagination.currentPage - 1) * parseInt(store.getState().categoryReducer.categories.per_page)) >= (store.getState().categoryReducer.categories.total  - 1)) {
                yield put({
                    type: actions.SET_PAGINATION, payload: {
                        ...store.getState().categoryReducer.pagination,
                        currentPage: store.getState().categoryReducer.categories.last_page - 1
                    }
                });
            }
        }
        yield put({ type: actions.GET_CATEGORIES });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}


export default categorySaga;
import {
    takeEvery, call, all,
    put
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'
import commonActions from '../common/actions';
import handleError from '../../utils/errors';
import { store } from '../store';

const teamSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_USERS, getUsers),
        yield takeEvery(actions.GET_ROLES, getRoles),
        yield takeEvery(actions.INVITE_MAIL, inviteUser),
        yield takeEvery(actions.GET_TEAMS, getTeams),
        yield takeEvery(actions.CREATE_TEAM, createTeam),
        yield takeEvery(actions.UPDATE_TEAM, updateTeam),
        yield takeEvery(actions.UPDATE_USER_TEAM, updateUserTeam),
        yield takeEvery(actions.UPDATE_USER, updateUser),
        yield takeEvery(actions.DELETE_TEAM, deleteTeam),
        yield takeEvery(actions.REMOVE_USER_FROM_TEAM, removeUserFromTeam),
        yield takeEvery(actions.ADD_USER_FROM_TEAM, addUserToTeam),
    ]);
};

const getUsers = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/users${store.getState().teamReducer.userPagination.currentPage === 1 ? "" : `?page=${store.getState().teamReducer.userPagination.currentPage}`}`, {
                params: {
                    status: store.getState().teamReducer.userFilterParams.status,
                    entries: store.getState().teamReducer.userPagination.entries,
                    name: store.getState().teamReducer.userFilterParams.name
                }
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));

        yield put({ type: actions.SET_USERS, payload: result?.data ? result.data.users : null });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_USERS, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}
const getRoles = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/user-roles`)
        );
        yield put({ type: actions.SET_ROLES, payload: result?.data ? result.data.roles : null });
        yield put({ type: commonActions.SET_LOADER, payload: false });

    } catch (err) {
        yield put({ type: actions.SET_USERS, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const getTeams = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/teams${store.getState().teamReducer.teamPagination.currentPage === 1 ? "" : `?page=${store.getState().teamReducer.teamPagination.currentPage}`}`, {
                params: {
                    status: store.getState().teamReducer.teamFilterParams.status,
                    entries: store.getState().teamReducer.teamPagination.entries,
                    name: store.getState().teamReducer.teamFilterParams.name
                }
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));

        yield put({ type: actions.SET_TEAMS, payload: result?.data ? result.data.teams : null });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_TEAMS, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const inviteUser = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(`${API_URL}/invite`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({ type: actions.GET_USERS });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }

}


const createTeam = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(`${API_URL}/teams`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_TEAMS });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const updateTeam = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.put(`${API_URL}/teams/${data.payload.id}`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_TEAMS });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const updateUserTeam = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.put(`${API_URL}/user-teams-update`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_USERS });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const updateUser = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.put(`${API_URL}/users/${data.payload.id}`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_USERS });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const removeUserFromTeam = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.delete(`${API_URL}/user-teams/${data.payload.id}`)
        );
        yield put({ type: actions.GET_TEAMS });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const addUserToTeam = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(`${API_URL}/user-teams`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_TEAMS });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}
const deleteTeam = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.delete(`${API_URL}/teams/${data.payload.id}`)
        );
        if (store.getState().teamReducer.teams && store.getState().teamReducer?.teamPagination.currentPage > 1 && store.getState().teamReducer?.teamPagination.currentPage === store.getState().teamReducer?.teams?.last_page) {
            if ((parseInt(store.getState().teamReducer.teamPagination.currentPage - 1) * parseInt(store.getState().teamReducer.teams.per_page)) >= (store.getState().teamReducer.teams.total - 1)) {
                yield put({
                    type: actions.SET_TEAM_PAGINATION, payload: {
                        ...store.getState().teamReducer.teamPagination,
                        currentPage: store.getState().teamReducer.teams.last_page - 1
                    }
                });
            }
        }
        yield put({ type: actions.GET_TEAMS });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

export default teamSaga;
const teamActions = {
    INVITE_MAIL: 'INVITE_MAIL',
    GET_USERS: 'GET_USERS',
    SET_USERS: 'SET_USERS',
    GET_ROLES: 'GET_ROLES',
    SET_ROLES: 'SET_ROLES',
    GET_TEAMS: 'GET_TEAMS',
    SET_TEAMS: 'SET_TEAMS',
    UPDATE_USER: 'UPDATE_USER',
    CREATE_TEAM: 'CREATE_TEAM',
    UPDATE_TEAM: 'UPDATE_TEAM',
    UPDATE_USER_TEAM: 'UPDATE_USER_TEAM',
    DELETE_TEAM: 'DELETE_TEAM',
    ADD_USER_FROM_TEAM: 'ADD_USER_FROM_TEAM',
    REMOVE_USER_FROM_TEAM: 'REMOVE_USER_FROM_TEAM',
    SET_USER_FILTER_PARAMS: 'SET_USER_FILTER_PARAMS',
    SET_TEAM_FILTER_PARAMS: 'SET_TEAM_FILTER_PARAMS',
    SET_USER_PAGINATION: 'SET_USER_PAGINATION',
    SET_TEAM_PAGINATION: 'SET_TEAM_PAGINATION',
};

export default teamActions;
import actions from "./actions";

const initialState = {
    timeTracks: null,
    pagination: {
        entries: 50,
        currentPage: 1
    },
    reset:false
};
const timeTrackReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_RESET:
            return {
                ...state,
                reset: action.payload
            }
        case actions.SET_TIMETRACKS:
            return {
                ...state,
                timeTracks: action.payload
            }
        case actions.SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            }
        default:
            return state;
    }
};

export default timeTrackReducer;
import React from 'react';
import { useSelector } from "react-redux";
const Loader = () => {
    const { loading } = useSelector((state) => state.commonReducer);
    return (
        loading &&
        <div className='loader'>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    );
};

export default Loader;
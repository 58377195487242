import {
    takeEvery, call, all,
    put
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'
import commonActions from '../common/actions';
import handleError from '../../utils/errors';
import moment from 'moment';
import { store } from '../store';

const timeTrackSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_TIMETRACKS, getTimeTracks),
        yield takeEvery(actions.CREATE_TIMETRACK, createTimeTrack),
        yield takeEvery(actions.UPDATE_TIMETRACK, updateTimeTrack),
        yield takeEvery(actions.DELETE_TIMETRACK, deleteTimeTrack)
    ]);
};

const getTimeTracks = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/timetracks${store.getState().timeTrackReducer.pagination.currentPage === 1 ? "" : `?page=${store.getState().timeTrackReducer.pagination.currentPage}`}`, {
                params: {
                    entries: store.getState().timeTrackReducer.pagination.entries,
                }
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));
        let init = result.data.timetracks;
        let b = [], c = [];
        let d = init?.data.map(el12 =>{ return {...el12,  millis: moment(el12.start_time).valueOf() }});
        init["data"] = d;
        init?.data?.forEach(val => {
            if (b.includes(moment(val.start_time).format('YYYY-MM-DD'))) {
                c[moment(val.start_time).format('YYYY-MM-DD')] = [val, ...c[moment(val.start_time).format('YYYY-MM-DD')]].sort(
                    (p1, p2) => (p1.millis < p2.millis) ? 1 : (p1.millis > p2.millis) ? -1 : 0)
            } else {
                b.push(moment(val.start_time).format('YYYY-MM-DD'));
                c[moment(val.start_time).format('YYYY-MM-DD')] = [val]
            }

        });
        init["data"] = c;
        yield put({ type: actions.SET_TIMETRACKS, payload: result?.data ? init : null });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_TIMETRACKS, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const createTimeTrack = function* (data) {
    let payload = data.payload;
    if (payload.start_time && payload.end_time) {
        if (moment(payload.end_time).valueOf() < moment(payload.start_time).valueOf()) {
            payload = {...data.payload, start_time:data.payload.end_time, end_time: data.payload.start_time}
            yield put({
                type: commonActions.SET_MESSAGE, payload: {
                    message: 'Start Time is greater than End Time', status: false
                }
            });
            return;
        }
    }
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(`${API_URL}/timetracks`, JSON.stringify(payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_TIMETRACKS });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({type:actions.SET_RESET,payload:true})
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const updateTimeTrack = function* (data) {
    let payload = data.payload;
    if (payload.start_time && payload.end_time) {
        if (moment(payload.end_time).valueOf() < moment(payload.start_time).valueOf()) {
            payload = {...data.payload, start_time:data.payload.end_time, end_time: data.payload.start_time}
            yield put({
                type: commonActions.SET_MESSAGE, payload: {
                    message: 'Start Time is greater than End Time', status: false
                }
            });
            return;
        }
    }
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.put(`${API_URL}/timetracks/${data.payload.id}`, JSON.stringify(payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({
            type: actions.GET_TIMETRACKS
        });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const deleteTimeTrack = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.delete(`${API_URL}/timetracks/${data.payload.id}`)
        );
        if (store.getState().timeTrackReducer.timeTracks && store.getState().timeTrackReducer?.pagination.currentPage > 1 && store.getState().timeTrackReducer?.pagination.currentPage === store.getState().timeTrackReducer?.timeTracks?.last_page) {
            if ((parseInt(store.getState().timeTrackReducer.pagination.currentPage - 1) * parseInt(store.getState().timeTrackReducer.timeTracks.per_page)) >= (store.getState().timeTrackReducer.timeTracks.total - 1)) {
                yield put({
                    type: actions.SET_PAGINATION, payload: {
                        ...store.getState().timeTrackReducer.pagination,
                        currentPage: store.getState().timeTrackReducer.timeTracks.last_page - 1
                    }
                });
            }
        }
        yield put({ type: actions.GET_TIMETRACKS });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}


export default timeTrackSaga;
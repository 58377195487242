import DarkLogo from '../../assets/images/logo-dark.png';
import LightLogo from '../../assets/images/logo-light.png';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import authActions from '../../redux/auth/actions';
import { useNavigate } from 'react-router';
import { Form } from 'react-bootstrap';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required('Email is required'),
});

const ForgotPassword = () => {

    const { register, handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const dispatch = useDispatch();
    const history = useNavigate();

    const onSubmit = (data) => {
        dispatch({
            type: authActions.SEND_FORGOT_PASSWORD_EMAIL, payload: {
                email: data.email,
            },
            history: history
        });
    }

    return (
        <div className="bg-pattern">
            <div className="bg-overlay"></div>
            <div className="account-pages my-5 pt-5">
                <div className="container">

                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-6 col-md-8">
                            <div className="card">
                                <div className="card-body p-4">
                                    <div className="">
                                        <div className="text-center">
                                            <a href="index.html" className="">
                                                <img src={DarkLogo} alt="" height="24"
                                                    className="auth-logo logo-dark mx-auto" />
                                                <img src={LightLogo} alt="" height="24"
                                                    className="auth-logo logo-light mx-auto" />
                                            </a>
                                        </div>
                                        <p className="mb-5 text-center">Forgot Password </p>
                                        <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="email">Email</label>
                                                        <Form.Control placeholder="Enter email" name="email" {...register('email')} />
                                                        <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                                                    </div>
                                                    <div className="d-grid mt-4">
                                                        <button className="btn btn-primary waves-effect waves-light"
                                                            type="submit">Send
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;

import Header from "../components/common/header";
import Sidebar from "../components/common/sidebar";
import Footer from "../components/common/footer";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import authActions from "../redux/auth/actions";

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required()
});

const validationSchema1 = Yup.object().shape({
    current_password: Yup.string().required(),
    new_password: Yup.string().required(),
    new_confirm_password: Yup.string().required('Password is mendatory')
        .oneOf([Yup.ref('new_password')], 'Passwords does not match'),
});

const Profile = () => {

    const { profileDetails } = useSelector(state => state.authReducer);
    const [profileImage, setProfileImage] = useState(null);
    const { handleSubmit } = useForm();
    const { register: register1, handleSubmit: handleSubmit1, reset: reset1, formState: { errors: errors1 } } = useForm({ resolver: yupResolver(validationSchema) });
    const { register: register2, reset: reset2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm({ resolver: yupResolver(validationSchema1) });
    const dispatch = useDispatch();

    const onProfileSubmit = (data) => {
        dispatch({
            type: authActions.UPDATE_PROFILE_IMAGE, payload: {
                image: profileImage
            }
        })
    };

    const onSubmit1 = (data) => {
        dispatch({
            type: authActions.UPDATE_PROFILE_DETAILS, payload: data
        })
    };

    const onSubmit2 = (data) => {
        dispatch({
            type: authActions.UPDATE_PASSWORD_DETAILS, payload: data
        })
    };

    useEffect(() => {
        reset1({
            'name': profileDetails?.name,
            'email': profileDetails?.email
        });
        reset2({
            'current_password': null,
            'new_password': null,
            'new_confirm_password': null
        });
        setProfileImage(null);
    }, [profileDetails]);

    useEffect(() => {
        dispatch({ type: authActions.GET_PROFILE_DETAILS })
    }, []);

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                    <h5>Profile photo</h5>
                                    <p>Formats: png, jpg, gif. Max size: 1 MB.</p>
                                    <div className="profile-image row col-md-12">
                                        <img className="rounded-circle" src={profileImage ? URL.createObjectURL(profileImage) : profileDetails?.image} alt="Setting" />
                                        {/* <div className="col-md-6">
                                            <form action="#">
                                                <div className="mb-0">
                                                    <input type="file" className="filestyle" data-input="false" data-buttonname="btn-secondary" />
                                                </div>
                                            </form>
                                        </div> */}

                                        <Form onSubmit={handleSubmit(onProfileSubmit)}>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <label>Profile</label><div className="mb-0">
                                                        <Form.Control type="file" className="filestyle" onChange={(e) => setProfileImage(e.target.files[0])} data-input="false" data-buttonname="btn-secondary" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-3 justify-content-end">
                                                <div className="col-md-1">
                                                    <Button className="btn btn-light me-1">
                                                        Cancel
                                                    </Button>
                                                </div>
                                                <div className="col-md-1">
                                                    <Button className="btn btn-success" type={'submit'}>
                                                        Update
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                        <hr />
                                    </div>

                                    <hr />
                                    <h5>Personal info</h5>
                                    <p>Your log-in credentials and the name that is displayed in reports.</p>
                                    <form onSubmit={handleSubmit1(onSubmit1)}>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label>Name</label>
                                                <Form.Control name="name" {...register1('name')} isInvalid={!!errors1.name} placeholder="Enter the name" />
                                                <Form.Control.Feedback type="invalid">{errors1.name?.message}</Form.Control.Feedback>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Email</label>
                                                <Form.Control name="email" {...register1('email')} isInvalid={!!errors1.email} disabled={true} placeholder="Enter the name" />
                                                <Form.Control.Feedback type="invalid">{errors1.email?.message}</Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="row mt-3 justify-content-end">
                                            <div className="col-md-1">
                                                <Button className="btn btn-light me-1">
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className="col-md-1">
                                                <Button className="btn btn-success" type={'submit'}>
                                                    Update
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                    <hr />
                                    <h5>Change Password</h5>
                                    <Form onSubmit={handleSubmit2(onSubmit2)}>
                                        <div className="row mt-3 my-3">
                                            <div className="col-md-3">
                                                <label>Old Password</label>
                                                <Form.Control type="password" name="current_password" {...register2('current_password')} isInvalid={!!errors2?.current_password} placeholder="Enter the old password" />
                                                <Form.Control.Feedback type="invalid">{errors2?.current_password?.message}</Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="row my-3">
                                            <div className="col-md-3">
                                                <label>New Password</label>
                                                <Form.Control type="password" name="new_password" {...register2('new_password')} isInvalid={!!errors2?.new_password} placeholder="Enter the new password" />
                                                <Form.Control.Feedback type="invalid">{errors2?.new_password?.message}</Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="row my-3">
                                            <div className="col-md-3">
                                                <label>Confirm new password</label>
                                                <Form.Control type="password" name="new_confirm_password" {...register2('new_confirm_password')} isInvalid={!!errors2?.new_confirm_password} placeholder="Enter the same new password" />
                                                <Form.Control.Feedback type="invalid">{errors2?.new_confirm_password?.message}</Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="row my-3 justify-content-end">
                                            <div className="col-md-1">
                                                <Button className="btn btn-light me-1">
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className="col-md-2">
                                                <Button className="btn btn-success" type={'submit'}>
                                                    Update Password
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>
            </div >
            <div className="rightbar-overlay"></div>
            <Footer />
        </>
    )
}

export default Profile;
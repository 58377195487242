const taskActions = {
    GET_TASKS: 'GET_TASKS',
    SET_TASKS: 'SET_TASKS',
    CREATE_TASK: 'CREATE_TASK',
    UPDATE_TASK: 'UPDATE_TASK',
    DELETE_TASK: 'DELETE_TASK',
    SET_FILTER_PARAMS: 'SET_FILTER_PARAMS_TASKS',
    SET_PAGINATION: 'SET_PAGINATION_TASKS',
};

export default taskActions;
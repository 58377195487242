import React from 'react';
import { useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import commonActions from '../../redux/common/actions';

function ToastAlert() {

    const dispatch = useDispatch();
    const { message, status } = useSelector((state) => state.commonReducer)

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                dispatch({
                    type: commonActions.SET_MESSAGE, payload: {
                        message: null,
                        status: null
                    }
                })
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [message, status]);

    return (message &&
        <Toast className={`${status ? 'success' : 'failure'}`} show={message} onClose={() => dispatch({
            type: commonActions.SET_MESSAGE, payload: {
                message: null,
                status: null
            }
        })}>
            {status ? <Toast.Header>
                <strong className="mr-auto">Success</strong>
            </Toast.Header> : <Toast.Header>
                <strong className="mr-auto">Error</strong>
            </Toast.Header>}

            <Toast.Body>{message}</Toast.Body>
        </Toast>
    );
}

export default React.memo(ToastAlert);
const projectSettingActions = {
    GET_PROJECT_SETTINGS: 'GET_PROJECT_SETTINGS',
    SET_PROJECT_SETTINGS: 'SET_PROJECT_SETTINGS',
    CREATE_PROJECT_SETTING: 'CREATE_PROJECT_SETTING',
    UPDATE_PROJECT_SETTING: 'UPDATE_PROJECT_SETTING',
    DELETE_PROJECT_SETTING: 'DELETE_PROJECT_SETTING',
    SET_FILTER_PARAMS: 'SET_FILTER_PARAMS_PROJECT_SETTINGS',
    SET_PAGINATION: 'SET_PAGINATION_PROJECT_SETTINGS',
    GET_ACTIVE_PROJECT_SETTINGS: 'GET_ACTIVE_PROJECT_SETTINGS',
    SET_ACTIVE_PROJECT_SETTINGS: 'SET_ACTIVE_PROJECT_SETTINGS',
};

export default projectSettingActions;
import {
    takeEvery, call, all,
    put
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'
import commonActions from '../common/actions';
import handleError from '../../utils/errors';
import { store } from '../store';

const projectSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_PROJECTS, getProjects),
        yield takeEvery(actions.GET_AVAILABLE_PROJECTS, getAvailableProjects),
        yield takeEvery(actions.CREATE_PROJECT, createProject),
        yield takeEvery(actions.UPDATE_PROJECT, updateProject),
        yield takeEvery(actions.DELETE_PROJECT, deleteProject)
    ]);
};

const getProjects = function* (data) {
    let temp = {};
    if (store.getState().projectReducer.clientsSettings.selectAll) {
        if (store.getState().projectReducer.clientsSettings.unSelectedIds?.length > 0) {
            temp['unselected_client_id'] = store.getState().projectReducer.clientsSettings.unSelectedIds.join(',');
        }
    }
    if (!store.getState().projectReducer.clientsSettings.selectAll) {
        if (store.getState().projectReducer.clientsSettings.selectedIds?.length > 0) {
            temp['client_id'] = store.getState().projectReducer.clientsSettings.selectedIds.join(',');
        }
    }
    var status = store.getState().projectReducer.filterParams.status;
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/projects${store.getState().projectReducer.pagination.currentPage === 1 ? "" : `?page=${store.getState().projectReducer.pagination.currentPage}`}`, {
                params: {
                    status: store.getState().projectReducer.filterParams.status,
                    entries: store.getState().projectReducer.pagination.entries,
                    billing: store.getState().projectReducer.filterParams.billing,
                    name: store.getState().projectReducer.filterParams.name,
                    ...temp
                }
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));

        yield put({ type: actions.SET_PROJECTS, payload: result?.data ? result.data.projects : null });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_PROJECTS, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const getAvailableProjects = function* (data) {
    yield put({ type: commonActions.SET_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/available-projects`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.SET_AVAILABLE_PROJECTS, payload: result?.data ? result.data.availableProjects : null });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const createProject = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(`${API_URL}/projects`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_PROJECTS });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const updateProject = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.put(`${API_URL}/projects/${data.payload.id}`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_PROJECTS });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const deleteProject = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.delete(`${API_URL}/projects/${data.payload.id}`)
        );
        if (store.getState().projectReducer.projects && store.getState().projectReducer?.pagination.currentPage > 1 && store.getState().projectReducer?.pagination.currentPage === store.getState().projectReducer?.projects?.last_page) {
            if ((parseInt(store.getState().projectReducer.pagination.currentPage - 1) * parseInt(store.getState().projectReducer.projects.per_page)) >= (store.getState().projectReducer.projects.total - 1)) {
                yield put({
                    type: actions.SET_PAGINATION, payload: {
                        ...store.getState().projectReducer.pagination,
                        currentPage: store.getState().projectReducer.projects.last_page - 1
                    }
                });
            }
        }
        yield put({ type: actions.GET_projects });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}


export default projectSaga;
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/common/header";
import Sidebar from "../components/common/sidebar";
import projectSettingActions from "../redux/project-settings/actions";
import Button from 'react-bootstrap/Button';
import { Form, Dropdown, Pagination } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import commonActions from "../redux/common/actions";
import Footer from "../components/common/footer";
import { debounce } from "lodash";
const validationSchema = Yup.object().shape({
    prefix: Yup.string().required(),
    sequence: Yup.number().required('sequence is required').typeError('sequence must be a number'),
    start_date: Yup
        .date()
        .required('Start date is required').typeError('end date must be a date'),
    end_date: Yup
        .date()
        .required('End date is required').typeError('end date must be a date'),
    status: Yup.boolean().required('Status is required').typeError('Status is required'),
});

const ProjectSettings = () => {

    const dispatch = useDispatch();
    const [modalDetails, setModalDetails] = useState(false);
    // const [modalDeleteDetails, setModalDeleteDetails] = useState(false);
    const { projectSettings, filterParams, pagination } = useSelector(state => state.projectSettingReducer);
    const form = useRef();
    const projectSettingName = useRef();
    const projSettingSelect = useRef();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });

    const handleClose = () => {
        setModalDetails(null);
        reset({
            'prefix': '',
            'sequence': "",
            'start_date': "",
            'end_date': "",
            'status': true
        })
    }

    const handleAddOpen = () => {
        setModalDetails(true);
    }

    const handleEditOpen = (e, el) => {
        if (e === "1") {
            reset({
                'prefix': el.prefix,
                'sequence': el.sequence,
                'start_date': moment(el.start_date).format('YYYY-MM-DD'),
                'end_date': moment(el.end_date).format('YYYY-MM-DD'),
                'status': el.status
            });
            setModalDetails(el);
        } else {
            // setModalDeleteDetails(el);
            dispatch({
                type: projectSettingActions.DELETE_PROJECT_SETTING, payload: { id: el?.id }
            });
            // dispatch({ type: commonActions.SET_LOADER, payload: true });
            // await axios.get(`${API_URL}/categories/delete-check/${b.id}`).then((el => {
            // if (el?.data?.link) {
            // dispatch({
            //     type: commonActions.SET_CONFIRMATION_MODAL, payload: {
            //         status: true,
            //         message: el.data.message
            //     }
            // });
            // dispatch({ type: commonActions.SET_LOADER, payload: false });
            // }
            // if (el?.data?.link === false) {
            //     dispatch({ type: commonActions.SET_LOADER, payload: false });
            // }
            // })).catch(err => {
            //     setModalDeleteDetails(null);
            //     dispatch({
            //         type: commonActions.SET_MESSAGE, payload: {
            //             message: "Server error occured", status: false
            //         }
            //     });
            // }
            // );

        }
    }

    const formSubmit = (e) => {
        e.preventDefault();
        form.current.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    }

    const onSubmit = (data) => {
        if (!!modalDetails?.prefix) {
            data['id'] = modalDetails?.id;
            data['start_date'] = moment(data['start_date']).format('YYYY-MM-DD');
            data['end_date'] = moment(data['end_date']).format('YYYY-MM-DD');
            dispatch({
                type: projectSettingActions.UPDATE_PROJECT_SETTING, payload: data
            });
        } else {
            data['start_date'] = moment(data['start_date']).format('YYYY-MM-DD');
            data['end_date'] = moment(data['end_date']).format('YYYY-MM-DD');
            dispatch({
                type: projectSettingActions.CREATE_PROJECT_SETTING, payload: data
            });
        }
    };

    const paginate = (page) => {
        dispatch({
            type: projectSettingActions.SET_PAGINATION, payload: {
                ...pagination,
                currentPage: page
            }
        });
    }

    const handleStatus = (e) => {
        dispatch({
            type: projectSettingActions.SET_FILTER_PARAMS, payload: {
                ...filterParams,
                name: projectSettingName.current.value,
                status: e.target.value
            }
        });
        dispatch({
            type: projectSettingActions.SET_PAGINATION, payload: {
                ...pagination,
                currentPage: 1
            }
        });
    }

    const handleName = (e) => {
        dispatch({
            type: projectSettingActions.SET_FILTER_PARAMS, payload: {
                ...filterParams,
                status: projSettingSelect.current.value,
                name: e.target.value
            }
        });
        dispatch({
            type: projectSettingActions.SET_PAGINATION, payload: {
                ...pagination,
                currentPage: 1
            }
        });
    };

    useEffect(() => {
        // setModalDeleteDetails(null);
        setModalDetails(null);
    }, []);

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: projectSettingActions.GET_PROJECT_SETTINGS });
    }, [filterParams, pagination]);

    const debouncedChangeHandler = useCallback(
        debounce(handleName, 400)
        , []);

    useEffect(() => {
        setModalDetails(null);
        reset({
            'prefix': '',
            'sequence': "",
            'start_date': "",
            'end_date': "",
            'status': true
        });
    }, [projectSettings]);

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                    <div className="tab-content p-3">
                                        <div className="tab-pane active" id="custom-home" role="tabpanel">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <select className="form-select" ref={projSettingSelect} defaultValue={filterParams?.status} onChange={handleStatus}>
                                                                        <option value={true}>Show Active</option>
                                                                        <option value={false}>Show Archive</option>
                                                                        <option value={"all"}>Show All</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <form className="app-search client-search">
                                                                        <div className="position-relative">
                                                                            <input type="text" onChange={debouncedChangeHandler} className="form-control" ref={projectSettingName} defaultValue={filterParams?.name} placeholder="Search by name" />
                                                                            <span className="ri-search-line"></span>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-3">
                                                            <button type="button" className="btn btn-outline-info waves-effect waves-light w-100" data-bs-toggle="modal" onClick={handleAddOpen}>Add Project Setting</button>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive mt-30">
                                                        <table className="table table-striped table-bordered dt-responsive nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Prefix</th>
                                                                    <th>Sequence</th>
                                                                    <th>IncrementalSequence</th>
                                                                    <th>Start Date</th>
                                                                    <th>End Date</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {projectSettings?.data?.map(el => {
                                                                    return (
                                                                        <tr key={'projectSettings' + el.id}>
                                                                            <td width="300">
                                                                                <h6>{el.prefix}</h6>
                                                                            </td>
                                                                            <td width="300">
                                                                                <h6>{el.sequence}</h6>
                                                                            </td>
                                                                            <td width="300">
                                                                                <h6>{el.incremental_sequence}</h6>
                                                                            </td>
                                                                            <td width="300">
                                                                                <h6>{el.start_date}</h6>
                                                                            </td>
                                                                            <td width="300">
                                                                                <h6>{el.end_date}</h6>
                                                                            </td>
                                                                            <td width="50">
                                                                                <Dropdown onSelect={(e) => handleEditOpen(e, el)}>
                                                                                    <div className="btn-toolbar justify-content-md-end" role="toolbar">
                                                                                        <div className="btn-group">
                                                                                            <Dropdown.Toggle variant="white" split id="dropdown-split-basic" className="btn dropdown-toggle">
                                                                                                <a type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="mdi mdi-dots-vertical ms-1"></i></a>
                                                                                            </Dropdown.Toggle>
                                                                                            <Dropdown.Menu align={'end'} >
                                                                                                <Dropdown.Item eventKey="1">Edit ProjectSetting</Dropdown.Item>
                                                                                                {/* <Dropdown.Item eventKey="2">Delete ProjectSetting</Dropdown.Item> */}
                                                                                            </Dropdown.Menu>
                                                                                        </div>
                                                                                    </div>
                                                                                </Dropdown>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                                {projectSettings?.data?.length === 0 && <tr key="no-data">
                                                                    <td></td>
                                                                    <td colSpan={5}>No Data Available</td>
                                                                    <td></td>
                                                                </tr>}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {projectSettings?.data.length &&
                                                        <Pagination>
                                                            {<Pagination.First onClick={() => projectSettings?.first_page_url ? paginate(1) : ""} />}
                                                            {<Pagination.Prev onClick={() => projectSettings?.prev_page_url ? paginate(projectSettings?.current_page - 1) : ""} />}
                                                            <Pagination.Item>{projectSettings?.from} - {projectSettings?.to} of {projectSettings?.total}</Pagination.Item>
                                                            {<Pagination.Next onClick={() => projectSettings?.next_page_url ? paginate(projectSettings?.current_page + 1) : ""} />}
                                                            {<Pagination.Last onClick={() => projectSettings?.last_page_url ? paginate(projectSettings?.last_page) : ""} />}
                                                        </Pagination>
                                                    }
                                                </div>
                                                <Modal show={!!modalDetails} onHide={handleClose}>
                                                    <Modal.Header className="py-3 px-4" closeButton>
                                                        <Modal.Title className="h5">{modalDetails?.prefix ? "Update ProjectSetting" : "Create ProjectSetting"}</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
                                                            <div className="row"></div>
                                                            <div className="col-12">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Prefix</label>
                                                                    <Form.Control name="prefix" {...register('prefix')} isInvalid={!!errors.prefix} placeholder="Enter the prefix" />
                                                                    <Form.Control.Feedback type="invalid">{errors.prefix?.message}</Form.Control.Feedback>
                                                                </div>
                                                                <hr />
                                                                <div className="mb-3">
                                                                    <label className="form-label">Sequence</label>
                                                                    <Form.Control name="sequence" type="number" {...register('sequence')} isInvalid={!!errors.sequence} placeholder="Enter the sequence" />
                                                                    <Form.Control.Feedback type="invalid">{errors.sequence?.message}</Form.Control.Feedback>
                                                                </div>
                                                                <hr />
                                                                <div className="mb-3">
                                                                    <label className="form-label">StartDate</label>
                                                                    <Form.Control type="date" name="start_date" {...register('start_date')} isInvalid={!!errors.start_date} placeholder="Enter the start date" />
                                                                    <Form.Control.Feedback type="invalid">{errors.start_date?.message}</Form.Control.Feedback>
                                                                </div>
                                                                <hr />
                                                                <div className="mb-3">
                                                                    <label className="form-label">EndDate</label>
                                                                    <Form.Control type="date" name="end_date" {...register('end_date')} isInvalid={!!errors.end_date} placeholder="Enter the endDate" />
                                                                    <Form.Control.Feedback type="invalid">{errors.end_date?.message}</Form.Control.Feedback>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <input type="checkbox" id="project-setting-status" name="status" {...register('status')} isInvalid={!!errors.status} />
                                                                    <label className="ml-1" htmlFor="project-setting-status" >Status</label>
                                                                    <Form.Control.Feedback type="invalid">{errors.status?.message}</Form.Control.Feedback>
                                                                </div>
                                                                <hr />
                                                            </div>

                                                        </Form>
                                                    </Modal.Body>
                                                    <Modal.Footer className="text-end">
                                                        <Button className="btn btn-light me-1" onClick={handleClose}>
                                                            Cancel
                                                        </Button>
                                                        <Button className="btn btn-success" onClick={formSubmit}>
                                                            {modalDetails?.prefix ? 'Update' : 'Create'}
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="custom-settings" role="tabpanel">
                                            Check the module and update the components
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ clear: "both" }}></div>

                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <div className="rightbar-overlay"></div>
        </>
    )
}

export default ProjectSettings;
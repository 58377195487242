const timeTrackActions = {
    GET_TIMETRACKS: 'GET_TIMETRACKS',
    SET_TIMETRACKS: 'SET_TIMETRACKS',
    CREATE_TIMETRACK: 'CREATE_TIMETRACK',
    UPDATE_TIMETRACK: 'UPDATE_TIMETRACK',
    DELETE_TIMETRACK: 'DELETE_TIMETRACK',
    SET_PAGINATION: 'SET_PAGINATION_TIMETRACKS',
    SET_RESET:"SET_RESET"
};

export default timeTrackActions;

import Header from "../components/common/header";
import Sidebar from "../components/common/sidebar";
import Footer from "../components/common/footer";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DropdownIndicator } from "../utils/helpers";
import AsyncSelect from "react-select/async";
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import reportActions from "../redux/reports/actions";
import { Dropdown, Pagination } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../utils/constant";
import { debounce } from "@material-ui/core";
import settingActions from "../redux/settings/actions";
import Filter from "../components/reports/filter";
import { Popover, Button, Tooltip } from "antd";
import Content from "../components/timetracker/content";
import Status from "../components/reports/status";
import Description from "../components/reports/description";
import Team from "../components/reports/team";
import User from "../components/reports/user";
import Project from "../components/reports/project";
import Client from "../components/reports/client";
import Task from "../components/reports/task";
import Files from "../components/reports/files";
import successImage from './../assets/images/success.png';
import cancelImage from './../assets/images/cancel.png';
import commonActions from "../redux/common/actions";

const style = {
    control: base => ({
        ...base,
        border: "1px solid #e8ebf3",
        padding: "7px 0px",
        ':hover': {
            borderColor: "#e8ebf3",
        },
        boxShadow: "none",
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 })

};
const Reports = () => {

    const duplicateDescription = useRef([]);
    const duplicateFromTime = useRef([]);
    const duplicateToTime = useRef([]);
    const duplicateBillArray = useRef([]);
    const duplicateDateArray = useRef([]);
    const duplicateDiffArray = useRef([]);

    const [state, setState] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });
    const dispatch = useDispatch();
    const buttonRef = useRef([]);
    const pageCount = useRef();
    const pageSelect = useRef();
    const { filterParams, reports, pagination, initialSettings,
        description, usersSettings, tasksSettings, clientsSettings, projectsSettings,
        teamsSettings, reset
    } = useSelector(state => state.reportReducer)
    const { settings } = useSelector(state => state.settingReducer);
    const [currentPage, setCurrentPage] = useState(null);
    var [showDuplicate, setShowDuplicate] = useState([])
    const billArray = useRef([]);
    const { start, end } = state;
    const handleCallback = (start, end) => {
        setState({ start, end });
        dispatch({
            type: reportActions.SET_FILTER_PARAMS, payload: {
                ...filterParams,
                start_time: moment(start).format('YYYY-MM-DD'),
                end_time: moment(end).format('YYYY-MM-DD')
            }
        });
    };

    const calcCommonEdit = (fr, toV) => {
        let a = moment(moment(fr), 'HH:mm').format('HH:mm') + ":00";
        let b = moment(moment(toV), 'HH:mm').format('HH:mm') + ":00";
        if (fr.split(':')[0] >= 12) {
            a = fr + ":00 pm";
        } else {
            a = fr + ":00 am";
        }
        if (toV.split(':')[0] >= 12) {
            b = toV + ":00 pm";
        } else {
            b = toV + ":00 am";
        }
        var startTime1 = moment(a.split(" ")[1], "HH:mm:ss a").format("HH:mm:ss a");
        var endTime1 = moment(b.split(" ")[1], "HH:mm:ss a").format("HH:mm:ss a");
        var diff = moment
            .utc(moment(endTime1, "HH:mm:ss a").diff(moment(startTime1, "HH:mm:ss a")))
            .format("HH:mm:ss");
        return diff;
    }


    function secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h < 10 ? "0" + h : (h) : "00";
        var mDisplay = m > 0 ? m < 10 ? (":0" + m) : (":" + m) : ":00";
        var sDisplay = s > 0 ? s < 10 ? (":0" + s) : (":" + s) : ":00";
        return hDisplay + mDisplay + sDisplay;
    }

    const timeToSeconds = (td) => {
        let a = calcCommonEdit(td.start_time, td.end_time).split(':');
        let seconds = 0;
        seconds = seconds + parseInt(a[0]) * 60 * 60;
        seconds = seconds + parseInt(a[1]) * 60;
        seconds = seconds + parseInt(a[2]);
        return secondsToHms(seconds);
    }

    const duplicateTrackeList = (val) => {
        if (!duplicateDateArray?.current[val.id]?.value) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "Date necessary", status: false
                }
            });
        } else if (!duplicateFromTime?.current[val.id]?.value) {

            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "From Time not valid.", status: false
                }
            });
        } else if (!duplicateToTime?.current[val.id]?.value) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "To Time not valid.", status: false
                }
            });
        } else if (!moment(duplicateDateArray?.current[val.id]?.value + ' ' + duplicateFromTime?.current[val.id]?.value, 'YYYY-MM-DD HH:mm:ss').isValid()) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "From Time not valid.", status: false
                }
            });
        } else if (!moment(duplicateDateArray?.current[val.id]?.value + ' ' + duplicateToTime?.current[val.id]?.value, 'YYYY-MM-DD HH:mm:ss').isValid()) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "To Time not valid.", status: false
                }
            });
        } else if (!val.project.id) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "Project field neccessary.", status: false
                }
            });
        } else if (!val.user_id.id) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "User field neccessary.", status: false
                }
            });
        } else if (!duplicateDescription?.current[val.id]?.value) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "Description necessary", status: false
                }
            });
        } else {
            dispatch({
                type: reportActions.DUPLICATE_REPORT, payload:
                {
                    "user_id": val.user_id.id,
                    "description": duplicateDescription.current[val.id].value,
                    "project_id": val.project.id,
                    "billable": duplicateBillArray.current[val.id].checked,
                    "task_id": val.task.id,
                    "start_time": moment(moment(duplicateDateArray.current[val.id].value).format('YYYY-MM-DD') + ' ' + duplicateFromTime.current[val.id].value + ':00', 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"),
                    "end_time": moment(moment(duplicateDateArray.current[val.id].value).format('YYYY-MM-DD') + ' ' + duplicateToTime.current[val.id].value + ':00', 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss")
                },
                page: pageSelect?.current?.value, url: currentPage ?? false
            });
        }
    }

    const label = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

    const timeTrackAction = (e, vl) => {
        if (e === "1") {
            setShowDuplicate([...showDuplicate, {
                "user_id": vl.user,
                "id": vl.id,
                "description": vl.description,
                "project": vl.project,
                "billable_amount": vl.billable_amount,
                "billable": vl.billable,
                "task": vl.task,
                "start_time": moment(vl.start_time).format('YYYY-MM-DD HH:mm:ss'),
                "end_time": moment(vl.end_time).format('YYYY-MM-DD HH:mm:ss')
            }]);
            // dispatch({
            //     type: reportActions.DUPLICATE_REPORT, payload: {
            //         "description": vl.description,
            //         "billable": vl.billable,
            //         "user_id": vl.user_id,
            //         "task_id": vl.task.id,
            //         "start_time": moment(vl.start_time).format('YYYY-MM-DD HH:mm:ss'),
            //         "end_time": moment(vl.end_time).format('YYYY-MM-DD HH:mm:ss')
            //     }
            // });
        }
        if (e === "2") {
            dispatch({
                type: reportActions.DELETE_REPORT, payload: {
                    id: vl.id
                },
                page: pageSelect?.current?.value, url: currentPage ?? false
            });
        }
    }

    const paginate = (page) => {
        dispatch({
            type: reportActions.SET_PAGINATION, payload: {
                ...pagination,
                currentPage: page
            }
        });
    }

    const changeTime = (event, prev) => {
        let filteredInput = event.target.value;
        if (event.target.value.includes(":")) {
            filteredInput = event.target.value.replace(":", '');
        }
        if (!event.relatedTarget) {
            if (!isNaN(parseInt(filteredInput))) {
                if (filteredInput.length === 4) {
                    event.target.value = filteredInput.substring(0, 2) + ":" + filteredInput.substring(2, 4);
                    if (filteredInput.substring(0, 2) > 23) {
                        event.target.value = prev;
                    } else if (filteredInput.substring(2, 4) > 59) {
                        event.target.value = prev;
                    }
                } else if (filteredInput.length === 3) {
                    event.target.value = "0" + filteredInput.substring(0, 1) + ":" + filteredInput.substring(1, 3);
                } else if (filteredInput.length === 2) {
                    event.target.value = filteredInput.substring(0, 2) + ":00";
                } else if (filteredInput.length === 2) {
                    event.target.value = "0" + filteredInput.substring(0, 1) + ":00";
                } else {
                    event.target.value = prev;
                }
            } else {
                event.target.value = prev;
            }
        }
        if (event.target.value[2] !== ":") {
            event.target.value = event.target.value[0].toString() + event.target.value[1].toString() + ":" + event.target.value[2].toString() + event.target.value[3].toString()
        }
    }

    const updateTimeTrack = (e, mode, vl, l) => {
        if (mode === "user") {
            dispatch({
                type: reportActions.UPDATE_REPORT, payload: {
                    id: vl.id,
                    "description": vl.description,
                    "billable": vl.billable,
                    "user_id": e.value,
                    "task_id": vl.task.id,
                    "start_time": moment(vl.start_time).format('YYYY-MM-DD HH:mm:ss'),
                    "end_time": moment(vl.end_time).format('YYYY-MM-DD HH:mm:ss')
                }
            });
        }
        if (mode === "description") {
            dispatch({
                type: reportActions.UPDATE_REPORT, payload: {
                    id: vl.id,
                    "description": e.target.value,
                    "billable": vl.billable,
                    "user_id": vl.user.id,
                    "task_id": vl.task.id,
                    "start_time": moment(vl.start_time).format('YYYY-MM-DD HH:mm:ss'),
                    "end_time": moment(vl.end_time).format('YYYY-MM-DD HH:mm:ss')
                }
            });
        }
        if (mode === "timetracker") {
            dispatch({
                type: reportActions.UPDATE_REPORT, payload: {
                    id: vl.id,
                    "description": vl.description,
                    "billable": vl.billable,
                    "task_id": e.id,
                    "user_id": vl.user.id,
                    "start_time": moment(vl.start_time).format('YYYY-MM-DD HH:mm:ss'),
                    "end_time": moment(vl.end_time).format('YYYY-MM-DD HH:mm:ss')
                }
            });
            buttonRef.current[l].click();
        }
        if (mode === "start") {
            changeTime(e, vl.start_time);
            dispatch({
                type: reportActions.UPDATE_REPORT, payload: {
                    id: vl.id,
                    "description": vl.description,
                    "billable": vl.billable,
                    "user_id": vl.user.id,
                    "task_id": vl.task.id,
                    "start_time": moment(moment(vl.start_time).format('YYYY-MM-DD') + ' ' + e.target.value, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"),
                    "end_time": moment(vl.end_time).format('YYYY-MM-DD HH:mm:ss')
                }
            });
        }
        if (mode === "end") {
            changeTime(e, vl.end_time);
            dispatch({
                type: reportActions.UPDATE_REPORT, payload: {
                    id: vl.id,
                    "description": vl.description,
                    "billable": vl.billable,
                    "user_id": vl.user.id,
                    "task_id": vl.task.id,
                    "start_time": moment(vl.start_time).format('YYYY-MM-DD HH:mm:ss'),
                    "end_time": moment(moment(vl.start_time).format('YYYY-MM-DD') + ' ' + e.target.value, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"),
                }
            });
        }
        if (mode === "date") {
            dispatch({
                type: reportActions.UPDATE_REPORT, payload: {
                    id: vl.id,
                    "description": vl.description,
                    "billable": vl.billable,
                    "user_id": vl.user.id,
                    "task_id": vl.task.id,
                    "start_time": moment(e.target.value + ' ' + moment(vl.start_time).format('HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"),
                    "end_time": moment(e.target.value + ' ' + moment(vl.end_time).format('HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss")
                }
            });
        }
        if (mode === "billing") {
            dispatch({
                type: reportActions.UPDATE_REPORT, payload: {
                    id: vl.id,
                    "description": vl.description,
                    "billable": !vl.billable,
                    "user_id": vl.user.id,
                    "task_id": vl.task.id,
                    "start_time": moment(vl.start_time).format('YYYY-MM-DD HH:mm:ss'),
                    "end_time": moment(vl.end_time).format('YYYY-MM-DD HH:mm:ss')
                }
            });
        }
    }

    const selectFocus = (event) => {
        event.target.select();
    }

    const applyFilter = () => {
        let data = filterParams;
        if (initialSettings.includes('client')) {
            data['client_id'] = clientsSettings.selectedIds;
            data['clientDetails'] = clientsSettings;
        } else {
            data['client_id'] = [];
            data['clientDetails'] = {
                selectAll: false,
                selectedIds: []
            };
        }
        if (initialSettings.includes('description')) {
            data['description'] = description;
        } else {
            data['description'] = [];
        }
        if (initialSettings.includes('project')) {
            data['project_id'] = projectsSettings.selectedIds;
            data['projectDetails'] = projectsSettings;
        } else {
            data['project_id'] = [];
            data['projectDetails'] = {
                selectAll: false,
                selectedIds: []
            };
        }
        if (initialSettings.includes('task')) {
            data['task_id'] = tasksSettings.selectedIds;
            data['taskDetails'] = tasksSettings;
        } else {
            data['task_id'] = [];
            data['taskDetails'] = {
                selectAll: false,
                selectedIds: []
            };
        }
        if (initialSettings.includes('team')) {
            data['team_id'] = teamsSettings.selectedIds;
            data['teamDetails'] = teamsSettings;
        } else {
            data['team_id'] = [];
            data['teamDetails'] = {
                selectAll: false,
                selectedIds: []
            };
        }
        if (initialSettings.includes('user')) {
            data['user_id'] = usersSettings.selectedIds;
            data['userDetails'] = usersSettings;
        } else {
            data['user_id'] = [];
            data['userDetails'] = {
                selectAll: false,
                selectedIds: []
            };
        }
        dispatch({
            type: reportActions.SET_FILTER_PARAMS, payload: {
                ...data
            }
        });
        dispatch({
            type: reportActions.SET_PAGINATION, payload: {
                entries: pageCount.current.value,
                currentPage: 1
            }
        });
    }

    const calcCommonDiff = (fr, toV) => {
        let a = fr + ":00";
        let b = toV + ":00";
        if (fr.split(':')[0] >= 12) {
            a = fr + ":00 pm";
        } else {
            a = fr + ":00 am";
        }
        if (toV.split(':')[0] >= 12) {
            b = toV + ":00 pm";
        } else {
            b = toV + ":00 am";
        }

        var startTime1 = moment(a, "HH:mm:ss a").format("HH:mm:ss a");
        var endTime1 = moment(b, "HH:mm:ss a").format("HH:mm:ss a");
        var diff = moment
            .utc(moment(endTime1, "HH:mm:ss a").diff(moment(startTime1, "HH:mm:ss a")))
            .format("HH:mm:ss");
        return diff;
    }

    const perPage = (e) => {
        dispatch({
            type: reportActions.SET_PAGINATION, payload: {
                currentPage: 1,
                entries: e.target.value
            }
        });
    }

    const changeDuplicateFromTime = (e, frm, id) => {
        changeTime(e, frm);
        duplicateDiffArray.current[id].value = calcCommonDiff(e.target.value, duplicateToTime.current[id].value);
    }

    const changeDuplicateToTime = (e, to1, id) => {
        changeTime(e, to1);
        duplicateDiffArray.current[id].value = calcCommonDiff(duplicateFromTime.current[id].value, e.target.value);
    }

    useEffect(() => {
        dispatch({
            type: reportActions.GET_REPORTS
        });
    }, [filterParams, pagination]);

    useEffect(() => {
        dispatch({ type: settingActions.GET_SETTINGS });
    }, []);

    useEffect(() => {
        if (reset) {
            setShowDuplicate([]);
            dispatch({
                type: reportActions.SET_RESET, payload: false
            });
        }
    }, [reset]);


    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body" >
                                    <div className="tab-content p-3">
                                        <div className="tab-pane active" id="custom-home" role="tabpanel">
                                            <div className="card">
                                                <div className="card-body" style={{ background: "#f3f3f4" }}>
                                                    <div className="row justify-content-end">
                                                        <div className="col-md-3">
                                                            <DateRangePicker
                                                                initialSettings={{
                                                                    startDate: start.toDate(),
                                                                    endDate: end.toDate(),
                                                                    ranges: {
                                                                        Today: [moment().toDate(), moment().toDate()],
                                                                        Yesterday: [
                                                                            moment().subtract(1, 'days').toDate(),
                                                                            moment().subtract(1, 'days').toDate(),
                                                                        ],
                                                                        'Last 7 Days': [
                                                                            moment().subtract(6, 'days').toDate(),
                                                                            moment().toDate(),
                                                                        ],
                                                                        // 'Last 30 Days': [
                                                                        //     moment().subtract(29, 'days').toDate(),
                                                                        //     moment().toDate(),
                                                                        // ],
                                                                        'This Month': [
                                                                            moment().startOf('month').toDate(),
                                                                            moment().endOf('month').toDate(),
                                                                        ],
                                                                        'Last Month': [
                                                                            moment().subtract(1, 'month').startOf('month').toDate(),
                                                                            moment().subtract(1, 'month').endOf('month').toDate(),
                                                                        ],
                                                                    },
                                                                }}
                                                                onCallback={handleCallback}
                                                            >
                                                                <div
                                                                    id="reportrange"
                                                                    className="col-4"
                                                                    style={{
                                                                        background: '#fff',
                                                                        cursor: 'pointer',
                                                                        padding: '5px 10px',
                                                                        border: '1px solid #ccc',
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <i className="fa fa-calendar"></i>&nbsp;
                                                                    <span>{label}</span> <i className="fa fa-caret-down"></i>
                                                                </div>
                                                            </DateRangePicker>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3 py-3 bg-white">
                                                        <div className="col-md-1 cursor-pointer">
                                                            <Popover
                                                                content={<Filter />}
                                                                title="Filter"
                                                                trigger="click"
                                                            >
                                                                <span>FILTER  <i className="fa fa-caret-down"></i></span>
                                                            </Popover>
                                                        </div>
                                                        {initialSettings.includes('team') ? <div className="col-md-1 cursor-pointer">
                                                            <Popover
                                                                content={<Team />}
                                                                title="Team"
                                                                trigger="click"
                                                            >
                                                                <span>Team  <i className="fa fa-caret-down"></i></span>
                                                            </Popover>
                                                        </div> : <></>}

                                                        {initialSettings.includes('status') ? <div className="col-md-1 cursor-pointer">
                                                            <Popover
                                                                placement="bottomLeft"
                                                                content={<Status />}
                                                                title="Billable"
                                                                trigger="click"
                                                            >
                                                                <span>Status  <i className="fa fa-caret-down"></i></span>
                                                            </Popover>
                                                        </div> : <></>}

                                                        {initialSettings.includes('user') ? <div className="col-md-1 cursor-pointer">
                                                            <Popover
                                                                content={<User />}
                                                                title="User"
                                                                trigger="click"
                                                            >
                                                                <span>User  <i className="fa fa-caret-down"></i></span>
                                                            </Popover>
                                                        </div> : <></>}

                                                        {initialSettings.includes('project') ? <div className="col-md-1 cursor-pointer">
                                                            <Popover
                                                                content={<Project />}
                                                                title="Project"
                                                                trigger="click"
                                                            >
                                                                <span>Project  <i className="fa fa-caret-down"></i></span>
                                                            </Popover>
                                                        </div> : <></>}

                                                        {initialSettings.includes('client') ? <div className="col-md-1 cursor-pointer">
                                                            <Popover
                                                                content={<Client />}
                                                                title="Client"
                                                                trigger="click"
                                                            >
                                                                <span>Client  <i className="fa fa-caret-down"></i></span>
                                                            </Popover>
                                                        </div> : <></>}

                                                        {initialSettings.includes('task') ? <div className="col-md-1 cursor-pointer">
                                                            <Popover
                                                                content={<Task />}
                                                                title="Task"
                                                                trigger="click"
                                                            >
                                                                <span>Task  <i className="fa fa-caret-down"></i></span>
                                                            </Popover>
                                                        </div> : <></>}

                                                        {initialSettings.includes('description') ? <div className="col-md-2 cursor-pointer">
                                                            <Popover
                                                                placement="bottomLeft"
                                                                content={<Description />}
                                                                title="Description"
                                                                trigger="click"
                                                                placeholder=""
                                                            >
                                                                <span>Description  <i className="fa fa-caret-down"></i></span>
                                                            </Popover>
                                                        </div> : <></>}

                                                        <div className="col-md-3 text-right">
                                                            <Button type="primary" onClick={applyFilter}>Apply Filter</Button>
                                                        </div>

                                                    </div>
                                                    <div className="table-responsive mt-30">
                                                        <div className="row mb-2">
                                                            <div className="col-md-6">
                                                                <strong>Total:</strong>
                                                                <span className="mr-2">{secondsToHms(reports?.total)} </span>
                                                                <strong> Billable:</strong>
                                                                <span className="mr-2"> {secondsToHms(reports?.billable)} </span>
                                                                <strong> Amount: </strong>
                                                                <span> {reports?.billableAmount}</span>
                                                            </div>
                                                            <div className="col-md-6 text-right cursor-pointer">
                                                                <div className="col-md-2">
                                                                    <Popover
                                                                        content={<Files />}
                                                                        title="Export"
                                                                        trigger="click"
                                                                    >
                                                                        <span>Export  <i className="fa fa-caret-down"></i></span>
                                                                    </Popover>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <table className="table table-striped table-bordered dt-responsive nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Description</th>
                                                                    <th>Task-Project</th>
                                                                    <th>Amount</th>
                                                                    <th>Billable</th>
                                                                    <th>User</th>
                                                                    <th>Time</th>
                                                                    <th>Duration</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <>
                                                                    {reports?.reports?.data?.map((el, l) => {
                                                                        return (
                                                                            <>
                                                                                <tr key={'reports?.reports' + el.id}>
                                                                                    <td key={'description2' + l}>
                                                                                        <input type="text" className="form-control" id="validationTooltip01" key={el.description}
                                                                                            onBlur={(e) => updateTimeTrack(e, 'description', el)} defaultValue={el.description} />
                                                                                    </td>

                                                                                    <td width="300">
                                                                                        <Popover
                                                                                            placement="bottomLeft"
                                                                                            content={<Content hide={(e) => {
                                                                                                billArray.current[l].checked = el.project.billable;
                                                                                                updateTimeTrack(e, 'timetracker', el, l);
                                                                                            }} />}
                                                                                            title=""
                                                                                            trigger="click"
                                                                                        >
                                                                                            <>
                                                                                                <a href="true" ref={el12 => buttonRef.current[l] = el12}
                                                                                                    className="text-primary remove-btn-stl cursor-pointer blue-color">{
                                                                                                        <Tooltip overlayStyle={{ maxWidth: '400px' }} title={<div>
                                                                                                            <p>Project:{el?.project.title}</p>
                                                                                                            <p>Task:{el?.task?.name}</p>
                                                                                                            <p>Client:{el?.project?.client.code + '-' + el?.project?.client.name}</p>
                                                                                                        </div>}>
                                                                                                            <span> {el?.project.title}:{el?.task?.name} - {el?.project?.client.code + '-' + el?.project?.client.name}</span>
                                                                                                        </Tooltip>}</a>
                                                                                            </>
                                                                                        </Popover>
                                                                                    </td>
                                                                                    <td>{el.billable_amount}</td>
                                                                                    <td className="icons-wrap">
                                                                                        <i className="fas fa-rupee-sign" style={{ color: el?.billable ? "#0ac074" : "inherit" }} onClick={(e) => updateTimeTrack(e, 'billing', el)} ></i>
                                                                                        <input type="checkbox" className="d-none" key={el.billable} defaultChecked={el.billable} ref={(el1) => billArray.current[l] = el1} />
                                                                                    </td>
                                                                                    <td className="mw-200">
                                                                                        <AsyncSelect
                                                                                            styles={style}
                                                                                            className="w-100"
                                                                                            components={{ DropdownIndicator }}
                                                                                            name={"client_id"}
                                                                                            openMenuOnClick={true}
                                                                                            menuPortalTarget={document.body}
                                                                                            defaultOptions={[
                                                                                                { value: el?.user.id, label: el?.user?.name }
                                                                                            ]}
                                                                                            getOptionLabel={(e) => e?.label}
                                                                                            getOptionValue={(e) => e?.value}
                                                                                            value={{ value: el?.user.id, label: el?.user?.name }}
                                                                                            loadOptions={debounce(async (inputValue, callback) => {
                                                                                                if (inputValue.trim().length > 2) {
                                                                                                    const data = await axios.get(`${API_URL}/users-search/${inputValue}`,
                                                                                                        {
                                                                                                            headers: {
                                                                                                                "Content-Type": "application/json",
                                                                                                            },
                                                                                                        })
                                                                                                    callback(data.data.users.map(el => { return { label: el.name, value: el.id } }));
                                                                                                } else {
                                                                                                    callback(null);
                                                                                                }
                                                                                            }, 1000)}
                                                                                            onChange={(e) => updateTimeTrack(e, 'user', el)}
                                                                                        /></td>
                                                                                    <td>
                                                                                        <input type="text" className="form-control" onBlur={(e) => updateTimeTrack(e, 'start', el)} onFocus={selectFocus} defaultValue={moment(el.start_time).format('HH:mm')} key={'start1' + l + moment(el.start_time).format('HH:mm')} />
                                                                                        <input type="text" className="form-control" onBlur={(e) => updateTimeTrack(e, 'end', el)} onFocus={selectFocus} defaultValue={moment(el.end_time).format('HH:mm')} key={'end1' + l + moment(el.start_time).format('HH:mm')} />
                                                                                        <input className="form-control" type="date" defaultValue={moment(el.start_time).format('YYYY-MM-DD')} min={!localStorage.getItem('ksp_roles')?.includes('admin') ? moment(new Date(settings?.locked_date)).format('YYYY-MM-DD') : undefined} onChange={(e) => updateTimeTrack(e, 'date', el)} max={moment(new Date()).format('YYYY-MM-DD')} id="example-date-input" />
                                                                                    </td>
                                                                                    <td>{timeToSeconds(el)}</td>
                                                                                    <td width="50">
                                                                                        <Dropdown onSelect={(e) => timeTrackAction(e, el)}>
                                                                                            <div className="btn-toolbar justify-content-md-end" role="toolbar">
                                                                                                <div className="btn-group">
                                                                                                    <Dropdown.Toggle variant="white" split id="dropdown-split-basic" className="btn dropdown-toggle">
                                                                                                        <a type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="mdi mdi-dots-vertical ms-1"></i></a>
                                                                                                    </Dropdown.Toggle>
                                                                                                    <Dropdown.Menu align="end">
                                                                                                        <Dropdown.Item eventKey="1">Duplicate Timetrack</Dropdown.Item>
                                                                                                        <Dropdown.Item eventKey="2">Delete Timetrack</Dropdown.Item>
                                                                                                    </Dropdown.Menu>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Dropdown>
                                                                                    </td>
                                                                                </tr>
                                                                                {showDuplicate?.length > 0 && showDuplicate?.find(el3 => el3.id === el.id) ?

                                                                                    <tr key={'time12' + el.id}>
                                                                                        <td key={'description123' + el.id} className="mw-100">
                                                                                            <input type="text" className="form-control"
                                                                                                ref={(el1) => duplicateDescription.current[el.id] = el1}
                                                                                                key={showDuplicate?.find(el3 => el3.id === el.id).description}
                                                                                                defaultValue={showDuplicate?.find(el3 => el3.id === el.id).description} />
                                                                                        </td>
                                                                                        <td width="300">
                                                                                            <Popover
                                                                                                placement="bottomLeft"
                                                                                                content={<Content hide={(a, b) => {
                                                                                                    let ab = showDuplicate?.find(el3 => el3.id === el.id);
                                                                                                    setShowDuplicate([...showDuplicate.filter(vl1 => vl1.id !== el.id), {
                                                                                                        billable: ab.billable,
                                                                                                        billable_amount: ab.billable_amount,
                                                                                                        description: ab.description,
                                                                                                        user_id: ab.user_id,
                                                                                                        start_time: ab.start_time,
                                                                                                        end_time: ab.end_time,
                                                                                                        project: b,
                                                                                                        task: a,
                                                                                                        id: ab.id,
                                                                                                    }])
                                                                                                }
                                                                                                } />}
                                                                                                title=""
                                                                                                id="popperList"
                                                                                                trigger="click"
                                                                                            >
                                                                                                <>
                                                                                                    <a href="true" className="text-primary remove-btn-stl cursor-pointer blue-color"
                                                                                                        ref={el12 => buttonRef.current[el.id] = el12}
                                                                                                    >{
                                                                                                            <Tooltip overlayStyle={{ maxWidth: '400px' }} title={<div>
                                                                                                                <p>Project:{showDuplicate?.find(el3 => el3.id === el.id)?.project.title}</p>
                                                                                                                <p>Task:{showDuplicate?.find(el3 => el3.id === el.id)?.task?.name}</p>
                                                                                                                <p>Client:{showDuplicate?.find(el3 => el3.id === el.id)?.project?.client?.code + '-' + showDuplicate?.find(el3 => el3.id === el.id)?.project?.client?.name}</p>
                                                                                                            </div>}>
                                                                                                                <span> {showDuplicate?.find(el3 => el3.id === el.id)?.project.title}:{showDuplicate?.find(el3 => el3.id === el.id)?.task?.name} - {showDuplicate?.find(el3 => el3.id === el.id)?.project?.client?.code + '-' + showDuplicate?.find(el3 => el3.id === el.id)?.project?.client.name}</span>
                                                                                                            </Tooltip>}
                                                                                                    </a>
                                                                                                </>
                                                                                            </Popover>
                                                                                        </td>
                                                                                        <td>{el.billable_amount}</td>
                                                                                        <td className="icons-wrap">
                                                                                            <i className="fas fa-rupee-sign" style={{ color: showDuplicate?.find(el3 => el3.id === el.id)?.billable ? "#0ac074" : "inherit" }} onClick={() => setShowDuplicate(showDuplicate.map(el123 => el123.id === el.id ? {
                                                                                                ...el123, billable: !duplicateBillArray.current[el.id].checked
                                                                                            } : el123))}></i>
                                                                                            <input type="checkbox" ref={(el123) => duplicateBillArray.current[el.id] = el123} className="d-none" key={showDuplicate?.find(el3 => el3.id === el.id).billable} defaultChecked={showDuplicate?.find(el3 => el3.id === el.id).billable} />
                                                                                        </td>

                                                                                        <td className="mw-200">
                                                                                            <AsyncSelect
                                                                                                styles={style}
                                                                                                className="w-100"
                                                                                                components={{ DropdownIndicator }}
                                                                                                name={"client_id"}
                                                                                                openMenuOnClick={true}
                                                                                                menuPortalTarget={document.body}
                                                                                                defaultOptions={[
                                                                                                    { id: showDuplicate?.find(el3 => el3.id === el.id)?.user_id.id, value: showDuplicate?.find(el3 => el3.id === el.id)?.user_id.id, label: showDuplicate?.find(el3 => el3.id === el.id)?.user_id?.name, name: showDuplicate?.find(el3 => el3.id === el.id)?.user_id?.name }
                                                                                                ]}
                                                                                                getOptionLabel={(e) => e?.label}
                                                                                                getOptionValue={(e) => e?.value}
                                                                                                value={{ id: showDuplicate?.find(el3 => el3.id === el.id)?.user_id.id, value: showDuplicate?.find(el3 => el3.id === el.id)?.user_id.id, label: showDuplicate?.find(el3 => el3.id === el.id)?.user_id?.name, name: showDuplicate?.find(el3 => el3.id === el.id)?.user_id?.name }}
                                                                                                loadOptions={debounce(async (inputValue, callback) => {
                                                                                                    if (inputValue.trim().length > 2) {
                                                                                                        const data = await axios.get(`${API_URL}/users-search/${inputValue}`,
                                                                                                            {
                                                                                                                headers: {
                                                                                                                    "Content-Type": "application/json",
                                                                                                                },
                                                                                                            })
                                                                                                        callback(data.data.users.map(el12 => { return { label: el12.name, name: el12.name, value: el12.id, id: el12.id } }));
                                                                                                    } else {
                                                                                                        callback(null);
                                                                                                    }
                                                                                                }, 1000)}
                                                                                                onChange={(e) => setShowDuplicate(showDuplicate.map(el21 => el21.id === el.id ? { ...el, user_id: e } : { el21 }))}
                                                                                            /></td>
                                                                                        <td className="mw-100">
                                                                                            <input type="text" className="form-control" ref={(el123) => duplicateFromTime.current[el.id] = el123} onFocus={selectFocus} onBlur={(e) => changeDuplicateFromTime(e, moment(showDuplicate?.find(el3 => el3.id === el.id).start_time).format('HH:mm'), el.id)} key={'start123' + el.id + moment(showDuplicate?.find(el3 => el3.id === el.id).start_time).format('HH:mm')} defaultValue={moment(showDuplicate?.find(el3 => el3.id === el.id).start_time).format('HH:mm')} />
                                                                                            <input type="text" className="form-control" ref={(el123) => duplicateToTime.current[el.id] = el123} onFocus={selectFocus} onBlur={(e) => changeDuplicateToTime(e, moment(showDuplicate?.find(el3 => el3.id === el.id).end_time).format('HH:mm'), el.id)} key={'end123' + el.id + moment(showDuplicate?.find(el3 => el3.id === el.id).end_time).format('HH:mm')} defaultValue={moment(showDuplicate?.find(el3 => el3.id === el.id).end_time).format('HH:mm')} />
                                                                                            <input ref={(el123) => duplicateDateArray.current[el.id] = el123} className="form-control" type="date" key={moment(showDuplicate?.find(el3 => el3.id === el.id).start_time).format('YYYY-MM-DD')} defaultValue={moment(showDuplicate?.find(el3 => el3.id === el.id).start_time).format('YYYY-MM-DD')} min={!localStorage.getItem('ksp_roles')?.includes('admin') ? moment(new Date(settings?.locked_date)).format('YYYY-MM-DD') : undefined} max={moment(new Date()).format('YYYY-MM-DD')} id="example-date-input" />
                                                                                        </td>
                                                                                        <td className="mw-110">
                                                                                            <input data-parsley-type="alphanum" disabled={true} ref={(el123) => duplicateDiffArray.current[el.id] = el123} type="text" className="form-control" key={'duplicates' + el.id} defaultValue={calcCommonEdit(showDuplicate?.find(el3 => el3.id === el.id).start_time, showDuplicate?.find(el3 => el3.id === el.id).end_time)} />
                                                                                        </td>
                                                                                        <td colSpan={2}>
                                                                                            <Dropdown
                                                                                            >
                                                                                                <div className="btn-toolbar justify-content-md-end" role="toolbar">
                                                                                                    <div className="duplicateIcons">
                                                                                                        <img src={successImage} alt="successTick" onClick={() => duplicateTrackeList(showDuplicate?.find(el3 => el3.id === el.id))} />
                                                                                                        <img src={cancelImage} alt="cancelTick" onClick={() => {
                                                                                                            setShowDuplicate(showDuplicate.filter((e) => e.id !== el.id))
                                                                                                        }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Dropdown>
                                                                                        </td>
                                                                                    </tr >
                                                                                    : <></>
                                                                                }
                                                                            </>
                                                                        );
                                                                    }
                                                                    )}
                                                                    {(!reports || reports?.reports?.data?.length === 0) && <tr key="no-data">
                                                                        <td></td>
                                                                        <td colSpan={4}>No Data Available</td>
                                                                        <td></td>
                                                                    </tr>}


                                                                </>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div>
                                                        <select onChange={perPage} ref={pageCount}>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option value="200">200</option>
                                                        </select>

                                                    </div>
                                                    {reports?.reports?.data?.length &&
                                                        <Pagination>
                                                            {<Pagination.First onClick={() => reports?.reports?.first_page_url ? paginate(1) : ""} />}
                                                            {<Pagination.Prev onClick={() => reports?.reports?.prev_page_url ? paginate(reports?.reports?.current_page - 1) : ""} />}
                                                            <Pagination.Item>{reports?.reports?.from} - {reports?.reports?.to} of {reports?.reports?.total}</Pagination.Item>
                                                            {<Pagination.Next onClick={() => reports?.reports?.next_page_url ? paginate(reports?.reports?.current_page + 1) : ""} />}
                                                            {<Pagination.Last onClick={() => reports?.reports?.last_page_url ? paginate(reports?.reports?.last_page) : ""} />}
                                                        </Pagination>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="custom-settings" role="tabpanel">
                                            Check the module and update the components
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ clear: "both" }}></div>

                        </div>
                    </div>

                    <Footer />
                </div >
            </div >
            <div className="rightbar-overlay"></div>
        </>
    )
}

export default Reports;
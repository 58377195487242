
import actions from "./actions";

const initialState = {
  loading: false,
  message: null,
  status: null,
  confirmationStatus: false,
  confirmationMessage: null

};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADER:
      return {
        ...state,
        loading: action.payload
      }
    case actions.SET_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationStatus: action.payload.status,
        confirmationMessage: action.payload.message
      }
    case actions.SET_MESSAGE:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status
      }
    default:
      return state;
  }
};

export default commonReducer;
const projectActions = {
    GET_PROJECTS: 'GET_PROJECTS',
    GET_AVAILABLE_PROJECTS: 'GET_AVAILABLE_PROJECTS',
    SET_AVAILABLE_PROJECTS: 'SET_AVAILABLE_PROJECTS',
    SET_PROJECTS: 'SET_PROJECTS',
    CREATE_PROJECT: 'CREATE_PROJECT',
    UPDATE_PROJECT: 'UPDATE_PROJECT',
    DELETE_PROJECT: 'DELETE_PROJECT',
    SET_FILTER_PARAMS: 'SET_FILTER_PARAMS_PROJECTS',
    SET_PAGINATION: 'SET_PAGINATION_PROJECTS',
    SET_CLIENTS_SETTINGS: 'SET_CLIENTS_SETTINGS_PROJECTS',
    SET_CLIENT_VALUE: 'SET_CLIENT_VALUE_PROJECTS',
};

export default projectActions;
const clientActions = {
    GET_CLIENTS: 'GET_CLIENTS',
    SET_CLIENTS: 'SET_CLIENTS',
    CREATE_CLIENT: 'CREATE_CLIENT',
    UPDATE_CLIENT: 'UPDATE_CLIENT',
    DELETE_CLIENT: 'DELETE_CLIENT',
    SET_FILTER_PARAMS: 'SET_FILTER_PARAMS_CLIENTS',
    SET_PAGINATION: 'SET_PAGINATION_CLIENTS',
};

export default clientActions;
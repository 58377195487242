import actions from "./actions";

const initialState = {
    subCategories: null,
    activeCategories: [],
    filterParams: {
        status: true,
        category_id: "",
        name: ""
    },
    pagination: {
        entries: 50,
        currentPage: 1
    }
};
const subCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_SUB_CATEGORIES:
            return {
                ...state,
                subCategories: action.payload
            }
        case actions.SET_FILTER_PARAMS:
            return {
                ...state,
                filterParams: action.payload
            }
        case actions.SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            }
        default:
            return state;
    }
};

export default subCategoryReducer;
import { useDispatch, useSelector } from "react-redux";
import reportActions from "../../redux/reports/actions";

const Description = () => {

    const { description } = useSelector(state => state.reportReducer);
    const dispatch = useDispatch();
    const handleDescription = (e) => {
        dispatch({
            type: reportActions.SET_DESCRIPTION_SETTINGS, payload: e.target.value
        })
    }
    return (

        <div>
            <div>
                <p><input className="form-control" defaultValue={description} onChange={handleDescription} /></p>
            </div>
        </div>
    )

};

export default Description;
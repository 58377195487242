import {
    takeEvery, call, all,
    put
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'
import commonActions from '../common/actions';
import handleError from '../../utils/errors';
import { store } from '../store';

const taskSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_TASKS, getTasks),
        yield takeEvery(actions.CREATE_TASK, createTask),
        yield takeEvery(actions.UPDATE_TASK, updateTask),
        yield takeEvery(actions.DELETE_TASK, deleteTask)
    ]);
};

const getTasks = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/projects/${window.location.pathname.split('/')[2]}/tasks${store.getState().taskReducer.pagination.currentPage === 1 ? "" : `?page=${store.getState().taskReducer.pagination.currentPage}`}`, {
                params: {
                    status: store.getState().taskReducer.filterParams.status,
                    entries: store.getState().taskReducer.pagination.entries,
                    name: store.getState().taskReducer.filterParams.name
                }
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));

        yield put({ type: actions.SET_TASKS, payload: result?.data ? result.data.tasks : null });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_TASKS, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}


const createTask = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(`${API_URL}/tasks`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_TASKS });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const updateTask = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.put(`${API_URL}/tasks/${data.payload.id}`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_TASKS });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const deleteTask = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.delete(`${API_URL}/tasks/${data.payload.id}`)
        );
        yield put({ type: actions.GET_TASKS });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}


export default taskSaga;
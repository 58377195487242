import actions from "./actions";

const initialState = {
    profileDetails: null,
    isAuthenticated: false
    
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_PROFILE_DETAILS:
            return {
                ...state,
                profileDetails: action.payload
            }
        case actions.SET_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: action.payload
            }
        default:
            return state;
    }
};

export default authReducer;
import { store } from "../../redux/store";
import { API_URL } from "../../utils/constant";

const ExportProject = () => {
    const saveExcel = () => {
        let url = `${API_URL}/projects-export`;
        if (store.getState().projectReducer.clientsSettings.selectAll) {
            if (store.getState().projectReducer.clientsSettings.unSelectedIds?.length > 0) {
                url = url + "?unselected_client_id=" + store.getState().projectReducer.clientsSettings.unSelectedIds.join(',');
            } else {
                url = url + "?unselected_client_id="
            }
        } else {
            url = url + "?unselected_client_id="
        }
        if (!store.getState().projectReducer.clientsSettings.selectAll) {
            if (store.getState().projectReducer.clientsSettings.selectedIds?.length > 0) {
                url = url + "&client_id=" + store.getState().projectReducer.clientsSettings.unSelectedIds.join(',');
            } else {
                url = url + "&client_id=";
            }
        } else {
            url = url + "&client_id=";
        }
        if (store.getState().projectReducer.filterParams.status) {
            url = url + "&status=" + store.getState().projectReducer.filterParams.status; 
        } else {
            url = url + "&status="; 
        }
        if (store.getState().projectReducer.filterParams.billing) {
            url = url + "&billing=" + store.getState().projectReducer.filterParams.billing; 
        } else {
             url = url + "&billing=";
        }
        if (store.getState().projectReducer.filterParams.name) {
            url = url + "&name=" + store.getState().projectReducer.filterParams.name;
        } else {
            url = url + "&name=";
        }
        url = url + `&type=xlsx`;
        const link = document.getElementById('excel-project-download-id');
        link.href = url;
        link.click();
    }

    const saveCSV = () => {
        let url = `${API_URL}/projects-export`;
        if (store.getState().projectReducer.clientsSettings.selectAll) {
            if (store.getState().projectReducer.clientsSettings.unSelectedIds?.length > 0) {
                url = url + "?unselected_client_id=" + store.getState().projectReducer.clientsSettings.unSelectedIds.join(',');
            } else {
                url = url + "?unselected_client_id="
            }
        } else {
            url = url + "?unselected_client_id="
        }
        if (!store.getState().projectReducer.clientsSettings.selectAll) {
            if (store.getState().projectReducer.clientsSettings.selectedIds?.length > 0) {
                url = url + "&client_id=" + store.getState().projectReducer.clientsSettings.unSelectedIds.join(',');
            } else {
                url = url + "&client_id=";
            }
        } else {
            url = url + "&client_id=";
        }
        if (store.getState().projectReducer.filterParams.status) {
            url = url + "&status=" + store.getState().projectReducer.filterParams.status; 
        } else {
            url = url + "&status="; 
        }
        if (store.getState().projectReducer.filterParams.billing) {
            url = url + "&billing=" + store.getState().projectReducer.filterParams.billing; 
        } else {
             url = url + "&billing=";
        }
        if (store.getState().projectReducer.filterParams.name) {
            url = url + "&name=" + store.getState().projectReducer.filterParams.name;
        } else {
            url = url + "&name=";
        }
        url = url + `&type=csv`;
        const link = document.getElementById('excel-project-download-id');
        link.href = url;
        link.click();
    }

    return (

        <div>
            <div className="cursor-pointer">
                <a href className="d-none" id="excel-project-download-id">EXPORT</a>
                <p><span onClick={saveExcel} >Save As Excel</span></p>
                <p>  <span onClick={saveCSV}>Save As CSV</span>
                </p>
            </div>
        </div>
    )

};

export default ExportProject;
import {
    takeEvery, call, all,
    put
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'
import commonActions from '../common/actions';
import handleError from '../../utils/errors';
import { store } from '../store';
import moment from 'moment';

const reportSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_REPORTS, getReports),
        yield takeEvery(actions.GET_EXCEL_REPORTS, getExcelReports),
        yield takeEvery(actions.DUPLICATE_REPORT, duplicateReport),
        yield takeEvery(actions.DELETE_REPORT, deleteReport),
        yield takeEvery(actions.UPDATE_REPORT, updateReport),
    ]);
};

const getReports = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        var clientParams = null;
        var teamParams = null;
        var projectParams = null;
        var taskParams = null;
        var userParams = null;
        var description = null;
        var status = null;
        let url = `${API_URL}/reports`;
        if (store.getState().reportReducer.pagination.currentPage > 1) {
            url = url + `?page=${store.getState().reportReducer.pagination.currentPage}`;
        } else {
            url = url + `?page=1`;
        }
        if (store.getState().reportReducer.pagination.entries) {
            url = url + `&entries=${store.getState().reportReducer.pagination.entries}`;
        }
        if (store.getState().reportReducer.filterParams.start_time) {
            url = url + `&start_time=${store.getState().reportReducer.filterParams.start_time}`;
        }
        if (store.getState().reportReducer.filterParams.end_time) {
            url = url + `&end_time=${store.getState().reportReducer.filterParams.end_time}`;
        }
        if (store.getState().reportReducer.initialSettings.includes('status')) {
            if (store.getState().reportReducer.filterParams.status.includes('all')) {
                status = "all"
            } else if (store.getState().reportReducer.filterParams.status.includes('billable') && store.getState().reportReducer.filterParams.status.includes('non-billable')) {
                status = "all"
            } else if (store.getState().reportReducer.filterParams.status.includes('billable') && !store.getState().reportReducer.filterParams.status.includes('non-billable')) {
                status = true;
            } else if (!store.getState().reportReducer.filterParams.status.includes('billable') && store.getState().reportReducer.filterParams.status.includes('non-billable')) {
                status = false;
            }
            url = url + `&status=${status}`;
        }
        if (store.getState().reportReducer.initialSettings.includes('description')) {
            description = store.getState().reportReducer.description;
            if (!!description) {
                url = url + `&description=${description}`;
            }
        }
        if (store.getState().reportReducer.initialSettings.includes('client')) {
            if (store.getState().reportReducer.filterParams?.client_id?.length > 0) {
                clientParams = store.getState().reportReducer.filterParams?.client_id.join(',');
            }
            if (!!clientParams) {
                url = url + `&client_id=${clientParams}`;
            }
            if (store.getState().reportReducer.filterParams?.clientDetails?.selectAll) {
                url = url + `&unselected_client_id=${store.getState().reportReducer.filterParams?.clientDetails.unSelectedIds.join(',')}`;
            }
        }
        if (store.getState().reportReducer.initialSettings.includes('team')) {
            if (store.getState().reportReducer.filterParams?.team_id) {
                if (store.getState().reportReducer.filterParams?.team_id?.length > 0) {
                    teamParams = store.getState().reportReducer.filterParams?.team_id.join(',');
                };
            }
            if (!!teamParams) {
                url = url + `&team_id=${teamParams}`;
            }
            if (store.getState().reportReducer.filterParams?.teamDetails?.selectAll) {
                url = url + `&unselected_team_id=${store.getState().reportReducer.filterParams?.teamDetails.unSelectedIds.join(',')}`;
            }
        }
        if (store.getState().reportReducer.initialSettings.includes('project')) {
            if (store.getState().reportReducer.filterParams?.project_id) {
                if (store.getState().reportReducer.filterParams?.project_id?.length > 0) {
                    projectParams = store.getState().reportReducer.filterParams?.project_id.join(',');
                }
                if (!!projectParams) {
                    url = url + `&project_id=${projectParams}`;
                }
            }
            if (store.getState().reportReducer.filterParams?.projectDetails?.selectAll) {
                url = url + `&unselected_project_id=${store.getState().reportReducer.filterParams?.projectDetails.unSelectedIds.join(',')}`;
            }
        }
        if (store.getState().reportReducer.initialSettings.includes('task')) {
            if (store.getState().reportReducer.filterParams?.task_id) {
                if (store.getState().reportReducer.filterParams?.task_id?.length > 0) {
                    taskParams = store.getState().reportReducer.filterParams?.task_id.join(',');
                }
                if (!!taskParams) {
                    url = url + `&task_id=${taskParams}`;
                }
            }
            if (store.getState().reportReducer.filterParams?.taskDetails?.selectAll) {
                url = url + `&unselected_task_id=${store.getState().reportReducer.filterParams?.taskDetails.unSelectedIds.join(',')}`;
            }
        }
        if (store.getState().reportReducer.initialSettings.includes('user')) {
            if (store.getState().reportReducer.filterParams?.user_id) {
                if (store.getState().reportReducer.filterParams?.user_id?.length > 0) {
                    userParams = store.getState().reportReducer.filterParams?.user_id.join(',');
                }
                if (!!userParams) {
                    url = url + `&user_id=${userParams}`;
                }
            }
            if (store.getState().reportReducer.filterParams?.userDetails?.selectAll) {
                url = url + `&unselected_user_id=${store.getState().reportReducer.filterParams?.userDetails.unSelectedIds.join(',')}`;
            }
        }
        const result = yield call(() =>
            axios.get(`${url}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));
        yield put({ type: actions.SET_REPORTS, payload: result?.data ? result.data : null });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_REPORTS, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}
const getExcelReports = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        yield call(() =>
            axios.get(`${API_URL}/reports/export${store.getState().reportReducer.pagination.currentPage === 1 ? "" : `?page=${store.getState().reportReducer.pagination.currentPage}`}`, {
                params: {
                    entries: store.getState().reportReducer.pagination.entries,
                    start_time: store.getState().reportReducer.filterParams.start_time,
                    end_time: store.getState().reportReducer.filterParams.end_time,

                    client_id: store.getState().reportReducer.filterParams?.client_id,
                    description: store.getState().reportReducer.filterParams?.description,
                    task_id: store.getState().reportReducer.filterParams?.task_id,
                    team_id: store.getState().reportReducer.filterParams?.team_id,
                    project_id: store.getState().reportReducer.filterParams?.project_id,

                    status: store.getState().reportReducer.filterParams?.status,
                }
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_REPORTS, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const updateReport = function* (data) {
    let payload = data.payload;
    if (payload.start_time && payload.end_time) {
        if (moment(payload.end_time).valueOf() < moment(payload.start_time).valueOf()) {
            payload = {...data.payload, start_time:data.payload.end_time, end_time: data.payload.start_time}
            yield put({
                type: commonActions.SET_MESSAGE, payload: {
                    message: 'Start Time is greater than End Time', status: false
                }
            });
            return;
        }
    }
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.put(`${API_URL}/reports/${data.payload.id}`, JSON.stringify(payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({
            type: actions.GET_REPORTS
        });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}
const duplicateReport = function* (data) {
    let payload = data.payload;
    if (payload.start_time && payload.end_time) {
        if (moment(payload.end_time).valueOf() < moment(payload.start_time).valueOf()) {
            payload = {...data.payload, start_time:data.payload.end_time, end_time: data.payload.start_time}
            yield put({
                type: commonActions.SET_MESSAGE, payload: {
                    message: 'Start Time is greater than End Time', status: false
                }
            });
            return;
        }
    }
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(`${API_URL}/reports/create`, JSON.stringify(payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({
            type: actions.GET_REPORTS
        });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({
            type: actions.SET_RESET, payload: true
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const deleteReport = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.delete(`${API_URL}/reports/${data.payload.id}`)
        );
        if (store.getState().reportReducer.reports && store.getState().reportReducer?.pagination.currentPage > 1 && store.getState().reportReducer?.pagination.currentPage === store.getState().reportReducer?.reports?.last_page) {
            if ((parseInt(store.getState().reportReducer.pagination.currentPage - 1) * parseInt(store.getState().reportReducer.reports.per_page)) >= (store.getState().reportReducer.reports.total - 1)) {
                yield put({
                    type: actions.SET_PAGINATION, payload: {
                        ...store.getState().reportReducer.pagination,
                        currentPage: store.getState().reportReducer.reports.last_page - 1
                    }
                });
            }
        }
        yield put({ type: actions.GET_REPORTS });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}




export default reportSaga;
const subCategoryActions = {
    GET_SUB_CATEGORIES: 'GET_SUB_CATEGORIES',
    SET_SUB_CATEGORIES: 'SET_SUB_CATEGORIES',
    CREATE_SUB_CATEGORY: 'CREATE_SUB_CATEGORY',
    UPDATE_SUB_CATEGORY: 'UPDATE_SUB_CATEGORY',
    DELETE_SUB_CATEGORY: 'DELETE_SUB_CATEGORY',
    SET_FILTER_PARAMS: 'SET_FILTER_PARAMS_SUB_CATEGORIES',
    SET_PAGINATION: 'SET_PAGINATION_SUB_CATEGORIES',
};

export default subCategoryActions;
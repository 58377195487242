import axios from "axios";
import authActions from "../redux/auth/actions";
import { store } from "../redux/store";

const setAuthToken = (token) => {
  store.dispatch({ type: authActions.SET_AUTHENTICATED, payload: !!token })
  if (token) {
    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};
export default setAuthToken;
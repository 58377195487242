import { store } from "../../redux/store";
import { API_URL } from "../../utils/constant";

const Files = () => {
    const saveExcel = () => {
        var clientParams = null;
        var teamParams = null;
        var projectParams = null;
        var taskParams = null;
        var userParams = null;
        var description = null;
        var status = null;
        let url = `${API_URL}/reports/export`;
        if (store.getState().reportReducer.pagination.currentPage > 1) {
            url = url + `?page=${store.getState().reportReducer.pagination.currentPage}`;
        } else {
            url = url + `?page=1`;
        }
        if (store.getState().reportReducer.pagination.entries) {
            url = url + `&entries=${store.getState().reportReducer.pagination.entries}`;
        }
        if (store.getState().reportReducer.filterParams.start_time) {
            url = url + `&start_time=${store.getState().reportReducer.filterParams.start_time}`;
        }
        if (store.getState().reportReducer.filterParams.end_time) {
            url = url + `&end_time=${store.getState().reportReducer.filterParams.end_time}`;
        }
        if (store.getState().reportReducer.initialSettings.includes('status')) {
            if (store.getState().reportReducer.filterParams.status.includes('all')) {
                status = "all"
            } else if (store.getState().reportReducer.filterParams.status.includes('billable') && store.getState().reportReducer.filterParams.status.includes('non-billable')) {
                status = "all"
            } else if (store.getState().reportReducer.filterParams.status.includes('billable') && !store.getState().reportReducer.filterParams.status.includes('non-billable')) {
                status = true;
            } else if (!store.getState().reportReducer.filterParams.status.includes('billable') && store.getState().reportReducer.filterParams.status.includes('non-billable')) {
                status = false;
            }
            url = url + `&status=${status}`;
        }
        if (store.getState().reportReducer.initialSettings.includes('description')) {
            description = store.getState().reportReducer.description;
            if (!!description) {
                url = url + `&description=${description}`;
            }
        }
        if (store.getState().reportReducer.initialSettings.includes('client')) {
            if (store.getState().reportReducer.filterParams?.client_id?.length > 0) {
                clientParams = store.getState().reportReducer.filterParams?.client_id.join(',');
            }
            if (!!clientParams) {
                url = url + `&client_id=${clientParams}`;
            }
            if (store.getState().reportReducer.filterParams?.clientDetails?.selectAll) {
                url = url + `&unselected_client_id=${store.getState().reportReducer.filterParams?.clientDetails.unSelectedIds.join(',')}`;
            }
        }
        if (store.getState().reportReducer.initialSettings.includes('team')) {
            if (store.getState().reportReducer.filterParams?.team_id) {
                if (store.getState().reportReducer.filterParams?.team_id?.length > 0) {
                    teamParams = store.getState().reportReducer.filterParams?.team_id.join(',');
                };
            }
            if (!!teamParams) {
                url = url + `&team_id=${teamParams}`;
            }
            if (store.getState().reportReducer.filterParams?.teamDetails?.selectAll) {
                url = url + `&unselected_team_id=${store.getState().reportReducer.filterParams?.teamDetails.unSelectedIds.join(',')}`;
            }
        }
        if (store.getState().reportReducer.initialSettings.includes('project')) {
            if (store.getState().reportReducer.filterParams?.project_id) {
                if (store.getState().reportReducer.filterParams?.project_id?.length > 0) {
                    projectParams = store.getState().reportReducer.filterParams?.project_id.join(',');
                }
                if (!!projectParams) {
                    url = url + `&project_id=${projectParams}`;
                }
            }
            if (store.getState().reportReducer.filterParams?.projectDetails?.selectAll) {
                url = url + `&unselected_project_id=${store.getState().reportReducer.filterParams?.projectDetails.unSelectedIds.join(',')}`;
            }
        }
        if (store.getState().reportReducer.initialSettings.includes('task')) {
            if (store.getState().reportReducer.filterParams?.task_id) {
                if (store.getState().reportReducer.filterParams?.task_id?.length > 0) {
                    taskParams = store.getState().reportReducer.filterParams?.task_id.join(',');
                }
                if (!!taskParams) {
                    url = url + `&task_id=${taskParams}`;
                }
            }
            if (store.getState().reportReducer.filterParams?.taskDetails?.selectAll) {
                url = url + `&unselected_task_id=${store.getState().reportReducer.filterParams?.taskDetails.unSelectedIds.join(',')}`;
            }
        }
        if (store.getState().reportReducer.initialSettings.includes('user')) {
            if (store.getState().reportReducer.filterParams?.user_id) {
                if (store.getState().reportReducer.filterParams?.user_id?.length > 0) {
                    userParams = store.getState().reportReducer.filterParams?.user_id.join(',');
                }
                if (!!userParams) {
                    url = url + `&user_id=${userParams}`;
                }
            }
            if (store.getState().reportReducer.filterParams?.userDetails?.selectAll) {
                url = url + `&unselected_user_id=${store.getState().reportReducer.filterParams?.userDetails.unSelectedIds.join(',')}`;
            }
        }
        url = url + `&type=xlsx`;


        const link = document.getElementById('excel-downlaod-id');
        link.href = url;
        link.click();
    }

    const saveCSV = () => {
        var clientParams = null;
        var teamParams = null;
        var projectParams = null;
        var taskParams = null;
        var userParams = null;
        var description = null;
        var status = null;
        debugger;
        let url = `${API_URL}/reports/export`;
        if (store.getState().reportReducer.pagination.currentPage > 1) {
            url = url + `?page=${store.getState().reportReducer.pagination.currentPage}`;
        } else {
            url = url + `?page=1`;
        }
        if (store.getState().reportReducer.pagination.entries) {
            url = url + `&entries=${store.getState().reportReducer.pagination.entries}`;
        }
        if (store.getState().reportReducer.filterParams.start_time) {
            url = url + `&start_time=${store.getState().reportReducer.filterParams.start_time}`;
        }
        if (store.getState().reportReducer.filterParams.end_time) {
            url = url + `&end_time=${store.getState().reportReducer.filterParams.end_time}`;
        }
        if (store.getState().reportReducer.initialSettings.includes('status')) {
            if (store.getState().reportReducer.filterParams.status.includes('all')) {
                status = "all"
            } else if (store.getState().reportReducer.filterParams.status.includes('billable') && store.getState().reportReducer.filterParams.status.includes('non-billable')) {
                status = "all"
            } else if (store.getState().reportReducer.filterParams.status.includes('billable') && !store.getState().reportReducer.filterParams.status.includes('non-billable')) {
                status = true;
            } else if (!store.getState().reportReducer.filterParams.status.includes('billable') && store.getState().reportReducer.filterParams.status.includes('non-billable')) {
                status = false;
            }
            url = url + `&status=${status}`;
        }
        if (store.getState().reportReducer.initialSettings.includes('description')) {
            description = store.getState().reportReducer.description;
            if (!!description) {
                url = url + `&description=${description}`;
            }
        }
        if (store.getState().reportReducer.initialSettings.includes('client')) {
            if (store.getState().reportReducer.filterParams?.client_id?.length > 0) {
                clientParams = store.getState().reportReducer.filterParams?.client_id.join(',');
            }
            if (!!clientParams) {
                url = url + `&client_id=${clientParams}`;
            }
        }
        if (store.getState().reportReducer.initialSettings.includes('team')) {
            if (store.getState().reportReducer.filterParams?.team_id) {
                if (store.getState().reportReducer.filterParams?.team_id?.length > 0) {
                    teamParams = store.getState().reportReducer.filterParams?.team_id.join(',');
                };
            }
            if (!!teamParams) {
                url = url + `&team_id=${teamParams}`;
            }
        }
        if (store.getState().reportReducer.initialSettings.includes('project')) {
            if (store.getState().reportReducer.filterParams?.project_id) {
                if (store.getState().reportReducer.filterParams?.project_id?.length > 0) {
                    projectParams = store.getState().reportReducer.filterParams?.project_id.join(',');
                }
                if (!!projectParams) {
                    url = url + `&project_id=${projectParams}`;
                }
            }
        }
        if (store.getState().reportReducer.initialSettings.includes('task')) {
            if (store.getState().reportReducer.filterParams?.task_id) {
                if (store.getState().reportReducer.filterParams?.task_id?.length > 0) {
                    taskParams = store.getState().reportReducer.filterParams?.task_id.join(',');
                }
                if (!!taskParams) {
                    url = url + `&task_id=${taskParams}`;
                }
            }
        }
        if (store.getState().reportReducer.initialSettings.includes('user')) {
            if (store.getState().reportReducer.filterParams?.user_id) {
                if (store.getState().reportReducer.filterParams?.user_id?.length > 0) {
                    userParams = store.getState().reportReducer.filterParams?.user_id.join(',');
                }
                if (!!userParams) {
                    url = url + `&user_id=${userParams}`;
                }
            }
        }
        url = url + `&type=csv`;


        const link = document.getElementById('excel-downlaod-id');
        link.href = url;
        link.click();
    }

    return (

        <div>
            <div className="cursor-pointer">
                <a href className="d-none" id="excel-downlaod-id">EXPORT</a>
                <p><span onClick={saveExcel} >Save As Excel</span></p>
                <p>  <span onClick={saveCSV}>Save As CSV</span>
                </p>
            </div>
        </div>
    )

};

export default Files;
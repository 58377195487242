import {
    takeEvery, call, all,
    put
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'
import commonActions from '../common/actions';
import handleError from '../../utils/errors';
import { store } from '../store';

const clientSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_CLIENTS, getClients),
        yield takeEvery(actions.CREATE_CLIENT, createClient),
        yield takeEvery(actions.UPDATE_CLIENT, updateClient),
        yield takeEvery(actions.DELETE_CLIENT, deleteClient)
    ]);
};

const getClients = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/clients${store.getState().clientReducer.pagination.currentPage === 1 ? "" : `?page=${store.getState().clientReducer.pagination.currentPage}`}`, {
                params: {
                    status: store.getState().clientReducer.filterParams.status,
                    entries: store.getState().clientReducer.pagination.entries,
                    name: store.getState().clientReducer.filterParams.name,
                }
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));

        yield put({ type: actions.SET_CLIENTS, payload: result?.data ? result.data.clients : null });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_CLIENTS, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}


const createClient = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(`${API_URL}/clients`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_CLIENTS });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const updateClient = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.put(`${API_URL}/clients/${data.payload.id}`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_CLIENTS });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const deleteClient = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.delete(`${API_URL}/clients/${data.payload.id}`)
        );
        if (store.getState().clientReducer.clients && store.getState().clientReducer?.pagination.currentPage > 1 && store.getState().clientReducer?.pagination.currentPage === store.getState().clientReducer?.clients?.last_page) {
            if ((parseInt(store.getState().clientReducer.pagination.currentPage - 1) * parseInt(store.getState().clientReducer.clients.per_page)) >= (store.getState().clientReducer.clients.total - 1)) {
                yield put({
                    type: actions.SET_PAGINATION, payload: {
                        ...store.getState().clientReducer.pagination,
                        currentPage: store.getState().clientReducer.clients.last_page - 1
                    }
                });
            }
        }
        yield put({ type: actions.GET_CLIENTS });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}


export default clientSaga;
const categoryActions = {
    GET_CATEGORIES: 'GET_CATEGORIES',
    SET_CATEGORIES: 'SET_CATEGORIES',
    GET_ACTIVE_CATEGORIES: 'GET_ACTIVE_CATEGORIES',
    SET_ACTIVE_CATEGORIES: 'SET_ACTIVE_CATEGORIES',
    CREATE_CATEGORY: 'CREATE_CATEGORY',
    UPDATE_CATEGORY: 'UPDATE_CATEGORY',
    DELETE_CATEGORY: 'DELETE_CATEGORY',
    SET_FILTER_PARAMS: 'SET_FILTER_PARAMS_CATEGORIES',
    SET_PAGINATION: 'SET_PAGINATION_CATEGORIES',
};

export default categoryActions;
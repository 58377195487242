import {
    takeEvery, call, all,
    put
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'
import commonActions from '../common/actions';
import handleError from '../../utils/errors';

const settingSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_SETTINGS, getSettings),
        yield takeEvery(actions.UDPATE_SETTINGS, updateSettings),
    ]);
};

const getSettings = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/settings`
            ));
        yield put({ type: actions.SET_SETTINGS, payload: result?.data ? result.data.settings : null });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_SETTINGS, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}


const updateSettings = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.put(`${API_URL}/settings`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_SETTINGS });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.GET_SETTINGS });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

export default settingSaga;
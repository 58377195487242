const authActions = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    GET_PROFILE_DETAILS: 'GET_PROFILE_DETAILS',
    SET_PROFILE_DETAILS: 'SET_PROFILE_DETAILS',
    UPDATE_PROFILE_IMAGE: 'UPDATE_PROFILE_IMAGE',
    UPDATE_PROFILE_DETAILS: 'UPDATE_PROFILE_DETAILS',
    UPDATE_PASSWORD_DETAILS: 'UPDATE_PASSWORD_DETAILS',
    SET_AUTHENTICATED: 'SET_AUTHENTICATED',
    SEND_INVITE: 'SEND_INVITE',
    SEND_OTP: 'SEND_OTP',
    SEND_FORGOT_PASSWORD_EMAIL: 'SEND_FORGOT_PASSWORD_EMAIL',
    RESET_PASSWORD: 'RESET_PASSWORD'
};

export default authActions;
import { components } from "react-select";

export const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src="https://cdn-icons-png.flaticon.com/512/54/54481.png" width="15" height="15" alt="" title="" className="img-small" />
        </components.DropdownIndicator>
    );
};


import moment from "moment";
import actions from "./actions";

const initialState = {
    reports: null,
    filterParams: {
        status: ["all"],
        start_time: moment().subtract(29, 'days').format('YYYY-MM-DD'),
        end_time: moment().format('YYYY-MM-DD'),
        clientDetails: {
            selectAll: false,
            selectedIds: []
        },
        taskDetails: {
            selectAll: false,
            selectedIds: []
        },
        userDetails: {
            selectAll: false,
            selectedIds: []
        },
        projectDetails: {
            selectAll: false,
            selectedIds: []
        },
        teamDetails: {
            selectAll: false,
            selectedIds: []
        },
        client_id: [],
        task_id: [],
        user_id: [],
        description: null,
        team_id: [],
        project_id: [],
    },
    initialSettings: [
        'client', 'description', 'project', 'status', 'task', 'team', 'user'
    ],

    teamsSettings: {
        selectAll: false,
        status: true,
        selectedIds: [],
        unSelectedIds: []
    },
    teamValue: null,

    projectsSettings: {
        selectAll: false,
        status: true,
        selectedIds: [],
        unSelectedIds: []
    },
    projectValue: null,

    clientsSettings: {
        selectAll: false,
        status: true,
        selectedIds: [],
        unSelectedIds: []
    },
    cientValue: null,

    tasksSettings: {
        selectAll: false,
        status: true,
        selectedIds: [],
        unSelectedIds: []
    },
    taskValue: null,

    usersSettings: {
        selectAll: false,
        status: true,
        selectedIds: [],
        unSelectedIds: []
    },
    userValue: null,

    pagination: {
        entries: 50,
        currentPage: 1
    },

    description: null,
    reset: false
};
const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_RESET:
            return {
                ...state,
                reset: action.payload
            }
        case actions.SET_REPORTS:
            return {
                ...state,
                reports: action.payload
            }
        case actions.SET_INITIAL_SETTINGS:
            return {
                ...state,
                initialSettings: action.payload
            }
        case actions.SET_DESCRIPTION_SETTINGS:
            return {
                ...state,
                description: action.payload
            }
        case actions.SET_TEAMS_SETTINGS:
            return {
                ...state,
                teamsSettings: action.payload
            }
        case actions.SET_TEAM_VALUE:
            return {
                ...state,
                teamValue: action.payload
            }
        case actions.SET_CLIENTS_SETTINGS:
            return {
                ...state,
                clientsSettings: action.payload
            }
        case actions.SET_CLIENT_VALUE:
            return {
                ...state,
                cientValue: action.payload
            }
        case actions.SET_TASKS_SETTINGS:
            return {
                ...state,
                tasksSettings: action.payload
            }
        case actions.SET_TASK_VALUE:
            return {
                ...state,
                taskValue: action.payload
            }

        case actions.SET_PROJECTS_SETTINGS:
            return {
                ...state,
                projectsSettings: action.payload
            }
        case actions.SET_PROJECT_VALUE:
            return {
                ...state,
                projectValue: action.payload
            }

        case actions.SET_USERS_SETTINGS:
            return {
                ...state,
                usersSettings: action.payload
            }
        case actions.SET_USER_VALUE:
            return {
                ...state,
                userValue: action.payload
            }

        case actions.SET_FILTER_PARAMS:
            return {
                ...state,
                filterParams: action.payload
            }
        case actions.SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            }
        default:
            return state;
    }
};

export default reportReducer;

import Header from "../components/common/header";
import Sidebar from "../components/common/sidebar";
import Footer from "../components/common/footer";
import { Nav, Tab, TabContainer, TabContent } from "react-bootstrap";
import Teams from "../components/teams/teams";
import Members from "../components/teams/members";

const Team = () => {

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                    <TabContainer defaultActiveKey="members">
                                        <Nav className="nav-tabs nav-justified nav-tabs-custom" style={{ pointerEvents: 'visible' }}>
                                            <Nav.Item className="cursor-pointer">
                                                <Nav.Link eventKey="members">   <span className="d-md-inline-block">Members</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="cursor-pointer">
                                                <Nav.Link eventKey="groups">
                                                    <span className="d-md-inline-block">Groups</span></Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <TabContent>
                                            <Tab.Pane eventKey="members">
                                                <Members />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="groups">
                                                <Teams />
                                            </Tab.Pane>
                                        </TabContent>
                                    </TabContainer>

                                </div>
                            </div>

                            <div style={{ clear: "both" }}></div>

                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
            <div className="rightbar-overlay"></div>
        </>
    )
}

export default Team;
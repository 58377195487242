import DarkLogo from '../../assets/images/logo-dark.png';
import LightLogo from '../../assets/images/logo-light.png';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import authActions from '../../redux/auth/actions';
import { useNavigate, useParams } from 'react-router';
import { Form } from 'react-bootstrap';

const validationSchema = Yup.object().shape({
    username: Yup.string()
        .required('username is required'),
    password: Yup.string()
        .required('password is required'),
});

const Invitaion = () => {

    const { register, handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const dispatch = useDispatch();
    const history = useNavigate();
    const params = useParams();

    const onSubmit = (data) => {
        dispatch({
            type: authActions.SEND_INVITE, payload: {
                name: data.username,
                password: data.password
            },
            code: params.slug,
            history: history
        });
    };

    return (
        <div className="bg-pattern">
            <div className="bg-overlay"></div>
            <div className="account-pages my-5 pt-5">
                <div className="container">

                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-6 col-md-8">
                            <div className="card">
                                <div className="card-body p-4">
                                    <div className="">
                                        <div className="text-center">
                                            <a href="index.html" className="">
                                                <img src={DarkLogo} alt="" height="24"
                                                    className="auth-logo logo-dark mx-auto" />
                                                <img src={LightLogo} alt="" height="24"
                                                    className="auth-logo logo-light mx-auto" />
                                            </a>
                                        </div>
                                        <h4 className="font-size-18 text-muted mt-2 text-center">Welcome to KSP!</h4>
                                        <p className="mb-2 text-center">Invitation </p>
                                        <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-2">
                                                        <label className="form-label" htmlFor="username">Username</label>
                                                        <Form.Control placeholder="Enter username" autoComplete='off' name="username" {...register('username')} />
                                                        <Form.Control.Feedback type="invalid">{errors.username?.message}</Form.Control.Feedback>
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="userpassword">Password</label>
                                                        <Form.Control type="password" placeholder="Enter password" name="password" {...register('password')} />
                                                        <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                                                    </div>

                                                    <div className="d-grid mt-4">
                                                        <button className="btn btn-primary waves-effect waves-light"
                                                            type="submit">Accept Invitaion
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Invitaion;
import { useDispatch, useSelector } from "react-redux";
import reportActions from "../../redux/reports/actions";

const Status = () => {

    const { filterParams } = useSelector(state => state.reportReducer);
    const dispatch = useDispatch();
    const handleStatus = (e, status) => {
        if (e.target.checked) {
            dispatch({
                type: reportActions.SET_FILTER_PARAMS, payload: {
                    ...filterParams, status: [...filterParams.status, status]
                }
            })
        } else {
            dispatch({
                type: reportActions.SET_FILTER_PARAMS, payload: {
                    ...filterParams, status: filterParams.status.filter(el => el !== status)
                }
            })
        }
    }
    return (

        <div>
            <div>
                <p><input type="checkbox" defaultChecked={filterParams.status.includes('all')} id="all-filter" onChange={(e) => handleStatus(e, 'all')} /> <label htmlFor="all-filter">All</label></p>
                <p><input type="checkbox" defaultChecked={filterParams.status.includes('billable')} id="billable-filter" onChange={(e) => handleStatus(e, 'billable')} /> <label htmlFor="billable-filter">Billable</label></p>
                <p><input type="checkbox" defaultChecked={filterParams.status.includes('non-billable')} id="non-billable-filter" onChange={(e) => handleStatus(e, 'non-billable')} /> <label htmlFor="non-billable-filter">NonBillable</label></p>
            </div>
        </div>
    )

};

export default Status;
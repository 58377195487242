import actions from "./actions";

const initialState = {
    projects: null,
    availableProjects: [],
    filterParams: {
        status: true,
        billing: "all",
        name: ""
    },
    pagination: {
        entries: 50,
        currentPage: 1
    },
    clientsSettings: {
        selectAll: false,
        status: true,
        selectedIds: [],
        unSelectedIds: []
    },
    cientValue: null
};
const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_CLIENTS_SETTINGS:
            return {
                ...state,
                clientsSettings: action.payload
            }

        case actions.SET_CLIENT_VALUE:
            return {
                ...state,
                cientValue: action.payload
            }
        case actions.SET_PROJECTS:
            return {
                ...state,
                projects: action.payload
            }
        case actions.SET_AVAILABLE_PROJECTS:
            return {
                ...state,
                availableProjects: action.payload
            }
        case actions.SET_FILTER_PARAMS:
            return {
                ...state,
                filterParams: action.payload
            }
        case actions.SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            }
        default:
            return state;
    }
};

export default projectReducer;
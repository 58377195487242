import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/common/header";
import Sidebar from "../components/common/sidebar";
import clientActions from "../redux/clients/actions";
import Button from 'react-bootstrap/Button';
import { Form, Dropdown, Pagination } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import commonActions from "../redux/common/actions";
import Footer from "../components/common/footer";
import { debounce } from "lodash";
const validationSchema = Yup.object().shape({
    code: Yup.string().required(),
    name: Yup.string().required(),
    status: Yup.boolean().required()
});

const Client = () => {

    const dispatch = useDispatch();
    const [modalDetails, setModalDetails] = useState(false);
    // const [modalDeleteDetails, setModalDeleteDetails] = useState(false);
    const { clients, filterParams, pagination } = useSelector(state => state.clientReducer);
    const form = useRef();
    const clientSelect = useRef();
    const clientName = useRef();
    const pageCount = useRef();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });

    const handleClose = () => {
        setModalDetails(null);
        reset({
            'name': '',
            'code': '',
            'status': true
        })
    }

    const handleAddOpen = () => {
        setModalDetails(true);
    }

    const handleEditOpen = async (e, el) => {
        if (e === "1") {
            reset({
                name: el.name,
                code: el.code,
                status: !!el.status,
            });
            setModalDetails(el);
        } else {
            // setModalDeleteDetails(el);
            dispatch({
                type: clientActions.DELETE_CLIENT, payload: { id: el?.id }
            });
        }
    }

    const formSubmit = (e) => {
        e.preventDefault();
        form.current.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    }

    const onSubmit = (data) => {
        if (!!modalDetails?.name) {
            data['id'] = modalDetails?.id;
            dispatch({
                type: clientActions.UPDATE_CLIENT, payload: data
            });
        } else {
            dispatch({
                type: clientActions.CREATE_CLIENT, payload: data
            });
        }
        setModalDetails(null);
        reset({
            'name': '',
            'code': '',
            'status': true
        });
    };

    const paginate = (page) => {
        dispatch({
            type: clientActions.SET_PAGINATION, payload: {
                entries: pageCount.current.value,
                currentPage: page
            }
        });
    }

    const handleStatus = (e) => {
        dispatch({
            type: clientActions.SET_FILTER_PARAMS, payload: {
                ...filterParams,
                name: clientName.current.value,
                status: e.target.value
            }
        });
        dispatch({
            type: clientActions.SET_PAGINATION, payload: {
                entries: pageCount.current.value,
                currentPage: 1
            }
        });
    }

    const handleName = (e) => {
        dispatch({
            type: clientActions.SET_FILTER_PARAMS, payload: {
                ...filterParams,
                status: clientSelect.current.value,
                name: e.target.value
            }
        });
        dispatch({
            type: clientActions.SET_PAGINATION, payload: {
                entries: pageCount.current.value,
                currentPage: 1
            }
        });
    }

    const perPage = (e) => {
        dispatch({
            type: clientActions.SET_PAGINATION, payload: {
                currentPage: 1,
                entries: e.target.value
            }
        });
    }

    useEffect(() => {
        // setModalDeleteDetails(null);
        setModalDetails(null);
    }, []);

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: clientActions.GET_CLIENTS });
    }, [filterParams, pagination]);

    const debouncedChangeHandler = useCallback(
        debounce(handleName, 400)
        , []);


    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                    <div className="tab-content p-3">
                                        <div className="tab-pane active" id="custom-home" role="tabpanel">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <select className="form-select" onChange={handleStatus} ref={clientSelect} defaultValue={filterParams?.status}>
                                                                        <option value={true}>Show Active</option>
                                                                        <option value={false}>Show Archive</option>
                                                                        <option value={"all"}>Show All</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <form className="app-search client-search">
                                                                        <div className="position-relative">
                                                                            <input type="text" onChange={debouncedChangeHandler} ref={clientName} defaultValue={filterParams?.name} className="form-control" placeholder="Search by name" />
                                                                            <span className="ri-search-line"></span>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-3">
                                                            <button type="button" className="btn btn-outline-info waves-effect waves-light w-100" data-bs-toggle="modal" onClick={handleAddOpen}>Add Client</button>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive mt-30">
                                                        <table className="table table-striped table-bordered dt-responsive nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Code</th>
                                                                    <th>Status</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {clients?.data?.map(el => {
                                                                    return (
                                                                        <tr key={'clients-' + el.id}>
                                                                            <td width="300">
                                                                                <h6>{el.name}</h6>
                                                                            </td>
                                                                            <td width="300">
                                                                                <h6>{el.code}</h6>
                                                                            </td>
                                                                            <td width="300">
                                                                                <h6>{el.status ? "Active" : "InActive"}</h6>
                                                                            </td>
                                                                            <td width="50">
                                                                                <Dropdown onSelect={(e) => handleEditOpen(e, el)}>
                                                                                    <div className="btn-toolbar justify-content-md-end" role="toolbar">
                                                                                        <div className="btn-group">
                                                                                            <Dropdown.Toggle variant="white" split id="dropdown-split-basic" className="btn dropdown-toggle">
                                                                                                <a type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="mdi mdi-dots-vertical ms-1"></i></a>
                                                                                            </Dropdown.Toggle>
                                                                                            <Dropdown.Menu align={'end'} >
                                                                                                <Dropdown.Item eventKey="1">Edit Client</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey="2">Delete Client</Dropdown.Item>
                                                                                            </Dropdown.Menu>
                                                                                        </div>
                                                                                    </div>
                                                                                </Dropdown>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                                {clients?.data?.length === 0 && <tr key="no-data">
                                                                    <td></td>
                                                                    <td colSpan={2}>No Data Available</td>
                                                                    <td></td>
                                                                </tr>}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div>
                                                        <select onChange={perPage} ref={pageCount}>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option value="200">200</option>
                                                        </select>
                                                    </div>
                                                    {clients?.data.length &&
                                                        <Pagination>
                                                            {<Pagination.First onClick={() => clients?.first_page_url ? paginate(1) : ""} />}
                                                            {<Pagination.Prev onClick={() => clients?.prev_page_url ? paginate(clients?.current_page - 1) : ""} />}
                                                            <Pagination.Item>{clients?.from} - {clients?.to} of {clients?.total}</Pagination.Item>
                                                            {<Pagination.Next onClick={() => clients?.next_page_url ? paginate(clients?.current_page + 1) : ""} />}
                                                            {<Pagination.Last onClick={() => clients?.last_page_url ? paginate(clients?.last_page) : ""} />}
                                                        </Pagination>
                                                    }
                                                </div>
                                                <Modal show={!!modalDetails} onHide={handleClose}>
                                                    <Modal.Header className="py-3 px-4" closeButton>
                                                        <Modal.Title className="h5">{modalDetails?.name ? "Update Client" : "Create Client"}</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
                                                            <div className="row"></div>
                                                            <div className="col-12">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Code</label>
                                                                    <Form.Control name="code" {...register('code')} isInvalid={!!errors.code} placeholder="Enter the code" />
                                                                    <Form.Control.Feedback type="invalid">{errors.code?.message}</Form.Control.Feedback>
                                                                </div>
                                                                <hr />
                                                                <div className="mb-3">
                                                                    <label className="form-label">Name</label>
                                                                    <Form.Control name="name" {...register('name')} isInvalid={!!errors.name} placeholder="Enter the name" />
                                                                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                                                </div>
                                                                <hr />
                                                                <div className="mb-3">
                                                                    <label className="form-label">Status</label>
                                                                    <select className="form-select form-control" name="status" {...register("status")}>
                                                                        <option value={true}>Active</option>
                                                                        <option value={false}>InActive</option>
                                                                    </select>
                                                                </div>
                                                                <hr />
                                                            </div>
                                                        </Form>
                                                    </Modal.Body>
                                                    <Modal.Footer className="text-end">
                                                        <Button className="btn btn-light me-1" onClick={handleClose}>
                                                            Cancel
                                                        </Button>
                                                        <Button className="btn btn-success" onClick={formSubmit}>
                                                            {modalDetails?.name ? 'Update' : 'Create'}
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="custom-settings" role="tabpanel">
                                            Check the module and update the components
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ clear: "both" }}></div>

                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <div className="rightbar-overlay"></div>
        </>
    )
}

export default Client;
import {
    takeEvery, call, all,
    put
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'
import commonActions from '../common/actions';
import handleError from '../../utils/errors';
import setAuthToken from '../../utils/setAuthToken';

const categorySaga = function* () {
    yield all([
        yield takeEvery(actions.LOGIN, userLogin),
        yield takeEvery(actions.SEND_OTP, sendOTP),
        yield takeEvery(actions.SEND_FORGOT_PASSWORD_EMAIL, sendForgotPassword),
        yield takeEvery(actions.RESET_PASSWORD, resetPassword),
        yield takeEvery(actions.LOGOUT, userLogout),
        yield takeEvery(actions.GET_PROFILE_DETAILS, getProfileDetails),
        yield takeEvery(actions.UPDATE_PROFILE_IMAGE, updateProfileImage),
        yield takeEvery(actions.UPDATE_PROFILE_DETAILS, updateProfileDetails),
        yield takeEvery(actions.UPDATE_PASSWORD_DETAILS, updatePasswordDetails),
        yield takeEvery(actions.SEND_INVITE, sendInvite),
    ]);
};

const userLogin = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(`${API_URL}/login`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        setAuthToken(result.data.token);
        localStorage.setItem('ksp_roles', result.data.roles);
        localStorage.setItem('ksp_user_name', result.data.user.name);
        localStorage.setItem('ksp_token', result.data.token);
        localStorage.setItem('ksp_user_id', result.data.user.id);
        localStorage.setItem('ksp_user_email', result.data.user.email);
        window.location.href = "/timetracker";
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const resetPassword = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(`${API_URL}/resetPassword`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        data.history('/login');
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}
const sendForgotPassword = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(`${API_URL}/sendPasswordRest`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        data.history('/otp', { state: data.payload.email });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const sendOTP = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(`${API_URL}/verifyPasswordRest`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        data.history('/confirm-password', { state: { otp: data.payload.otp, email: data.payload.email } });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const getProfileDetails = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/profile`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        localStorage.setItem('ksp_roles', result.data.roles);
        localStorage.setItem('ksp_user_name', result.data.name);
        localStorage.setItem('ksp_user_id', result.data.id);
        localStorage.setItem('ksp_user_email', result.data.email);
        yield put({
            type: actions.SET_PROFILE_DETAILS, payload: result.data
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const updateProfileImage = function* (data) {
    try {
        var form_data = new FormData();
        form_data.append('image', data.payload.image);
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(`${API_URL}/profile-image`, form_data)
        );
        yield put({
            type: actions.GET_PROFILE_DETAILS
        });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }

}
const updateProfileDetails = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.put(`${API_URL}/profile`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({
            type: actions.SET_PROFILE_DETAILS, payload: result.data
        });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }

}
const updatePasswordDetails = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.put(`${API_URL}/profile-password`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({
            type: actions.GET_PROFILE_DETAILS
        });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}
const sendInvite = function* (data) {

    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.post(`${API_URL}/accept/${data.code}`, null, {
                params: data.payload
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: commonActions.SET_LOADER, payload: false });
        data.history('/login');
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
    } catch (err) {
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const userLogout = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/users/logout`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        localStorage.removeItem('ksp_roles');
        localStorage.removeItem('ksp_user_name');
        localStorage.removeItem('ksp_token');
        localStorage.removeItem('ksp_user_id');
        localStorage.removeItem('ksp_user_email');
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: result.data.message, status: true
            }
        });
        data.history('/login');
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        localStorage.removeItem('ksp_roles');
        localStorage.removeItem('ksp_user_name');
        localStorage.removeItem('ksp_token');
        localStorage.removeItem('ksp_user_id');
        localStorage.removeItem('ksp_user_email');
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}


export default categorySaga;
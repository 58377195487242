
import { DropdownIndicator } from "../../utils/helpers";
import { API_URL } from "../../utils/constant";
import { debounce as debounce1 } from "lodash";
import { debounce } from "@material-ui/core";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Form, Modal, Pagination } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import teamActions from "../../redux/team/actions";
import commonActions from "../../redux/common/actions";

const style = {
    control: base => ({
        ...base,
        border: "1px solid #e8ebf3",
        padding: "7px 0px",
        ':hover': {
            borderColor: "#e8ebf3",
        },
        boxShadow: "none",
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 })

};

const validationSchema1 = Yup.object().shape({
    name: Yup.string().required()
});


const Teams = () => {

    const { register: register1, handleSubmit: handleSubmit1, reset: reset1, formState: { errors: errors1 } } = useForm({ resolver: yupResolver(validationSchema1) });
    const [modalDetails, setModalDetails] = useState(false);
    // const [modalDeleteDetails, setModalDeleteDetails] = useState(false);
    const { teams, teamFilterParams, teamPagination } = useSelector(state => state.teamReducer);
    const selectInputRef = useRef([]);
    const teamName = useRef();
    const teamSelect = useRef();
    const teamForm = useRef();
    const dispatch = useDispatch();
    const pageCount = useRef();
    const handleInviteAddOpen = () => {
        setModalDetails(true);
    }

    const removeUserFromTeam = (ID) => {
        dispatch({
            type: teamActions.REMOVE_USER_FROM_TEAM, payload: {
                id: ID
            }
        })
    }

    const perPage = (e) => {
        dispatch({
            type: teamActions.SET_TEAM_PAGINATION, payload: {
                currentPage: 1,
                entries: e.target.value
            }
        });
    }

    const changeTeam = (teamId, e, index) => {
        dispatch({
            type: teamActions.ADD_USER_FROM_TEAM, payload: {
                team_id: teamId,
                user_id: e.id
            }
        });
        selectInputRef.current[index].select.clearValue();
    }

    const handleEditOpen1 = (e, b) => {
        if (e === "1") {
            reset1({
                name: b.name,
                status: !!b.status,
            });
            setModalDetails(b);
        } else {
            // setModalDeleteDetails(b);
            dispatch({
                type: teamActions.DELETE_TEAM, payload: { id: b?.id }
            });
            // dispatch({ type: commonActions.SET_LOADER, payload: true });
            // await axios.get(`${API_URL}/categories/delete-check/${b.id}`).then((el => {
            // if (el?.data?.link) {
            // dispatch({
            //     type: commonActions.SET_CONFIRMATION_MODAL, payload: {
            //         status: true,
            //         message: el.data.message
            //     }
            // });
            // dispatch({ type: commonActions.SET_LOADER, payload: false });
            // }
            // if (el?.data?.link === false) {
            //     dispatch({ type: commonActions.SET_LOADER, payload: false });
            // }
            // })).catch(err => {
            //     setModalDeleteDetails(null);
            //     dispatch({
            //         type: commonActions.SET_MESSAGE, payload: {
            //             message: "Server error occured", status: false
            //         }
            //     });
            // }
            // );

        }
    }

    const handleTeamClose = () => {
        setModalDetails(null);
        // setModalDeleteDetails(null);
        reset1({
            name: null,
            status: true
        });
    }

    const formTeamSubmit = (e) => {
        e.preventDefault();
        teamForm.current.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    };

    const onTeamSubmit = (data) => {
        if (!!modalDetails?.name) {
            data['id'] = modalDetails?.id;
            data['status'] = data['status'] === "true";
            dispatch({
                type: teamActions.UPDATE_TEAM, payload: data
            });
        } else {
            data['status'] = data['status'] === "true";
            dispatch({
                type: teamActions.CREATE_TEAM, payload: data
            });
        }
    }

    const paginate = (page) => {
        dispatch({
            type: teamActions.SET_TEAM_PAGINATION, payload: {
                entries: pageCount.current.value,
                currentPage: page
            }
        });
    }

    const handleStatus = (e) => {
        dispatch({
            type: teamActions.SET_TEAM_FILTER_PARAMS, payload: {
                name: teamName.current.value,
                status: e.target.value
            }
        });
        dispatch({
            type: teamActions.SET_TEAM_PAGINATION, payload: {
                entries: pageCount.current.value,
                currentPage: 1
            }
        });
    }

    const handleName = (e) => {
        dispatch({
            type: teamActions.SET_TEAM_FILTER_PARAMS, payload: {
                status: teamSelect.current.value,
                name: e.target.value
            }
        });
        dispatch({
            type: teamActions.SET_TEAM_PAGINATION, payload: {
                entries: pageCount.current.value,
                currentPage: 1
            }
        });
    }

    useEffect(() => {
        handleTeamClose();
    }, [teams]);


    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: teamActions.GET_TEAMS });
    }, [teamFilterParams, teamPagination]);

    const debouncedChangeHandler = useCallback(
        debounce1(handleName, 400)
        , []);

    return (
        <>

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-4">
                                    <select className="form-select" onChange={handleStatus} defaultValue={teamFilterParams?.status} ref={teamSelect}>
                                        <option value={true}>Show Active</option>
                                        <option value={false}>Show Archive</option>
                                        <option value={"all"}>Show All</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <form className="app-search client-search">
                                        <div className="position-relative">
                                            <input type="text" onChange={debouncedChangeHandler} defaultValue={teamFilterParams?.name} className="form-control" placeholder="Search by name" ref={teamName} />
                                            <span className="ri-search-line"></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-3">
                            <button type="button" className="btn btn-outline-info waves-effect waves-light w-100" data-bs-toggle="modal" onClick={handleInviteAddOpen}>Add Team</button>
                        </div>
                    </div>
                    <div className="table-responsive mt-30">
                        <table className="table table-striped table-bordered dt-responsive nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Access</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {teams?.data?.map((el, index) => {
                                    return (
                                        <tr key={'teams-data' + el.id}>
                                            <td width="300">
                                                <h6>{el.name}</h6>
                                            </td>
                                            <td width="300">
                                                {el?.users?.map(e => <div key={"internal-users" + e.name} className="d-flex">
                                                    <h6>{e.name}</h6> <button type="button" onClick={() => removeUserFromTeam(e.pivot.id)}>X</button>
                                                </div>)}
                                                <AsyncSelect
                                                    styles={style}
                                                    // {...field}
                                                    // ref={rf => selectInputRef[index] = rf}
                                                    className="w-100"
                                                    components={{ DropdownIndicator }}
                                                    name={"client_id"}
                                                    openMenuOnClick={true}
                                                    menuPortalTarget={document.body}
                                                    getOptionLabel={(e) => e?.label}
                                                    getOptionValue={(e) => e?.id}
                                                    loadOptions={debounce(async (inputValue, callback) => {
                                                        if (inputValue.trim().length > 2) {
                                                            const data = await axios.get(`${API_URL}/users-teams-search/${el.id}/${inputValue}`,
                                                                {
                                                                    headers: {
                                                                        "Content-Type": "application/json",
                                                                    },
                                                                })
                                                            callback(data.data.users.map(el => { return { name: el.name, label: el.name, value: el.id, id: el.id } }));
                                                        } else {
                                                            callback(null);
                                                        }
                                                    }, 1000)}
                                                    onChange={(e) => changeTeam(el.id, e, index)}
                                                />
                                            </td>
                                            <td width="50">
                                                <Dropdown onSelect={(e) => handleEditOpen1(e, el)}>
                                                    <div className="btn-toolbar justify-content-md-end" role="toolbar">
                                                        <div className="btn-group">
                                                            <Dropdown.Toggle variant="white" split id="dropdown-split-basic" className="btn dropdown-toggle">
                                                                <a type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="mdi mdi-dots-vertical ms-1"></i></a>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu align={'end'} >
                                                                <Dropdown.Item eventKey="1">Edit Team</Dropdown.Item>
                                                                {localStorage.getItem('ksp_roles')?.includes('super_admin') &&
                                                                    <Dropdown.Item eventKey="2">Delete Team</Dropdown.Item>}
                                                            </Dropdown.Menu>
                                                        </div>
                                                    </div>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                                {!teams && <tr key="no-data">
                                    <td></td>
                                    <td colSpan={2}>No Data Available</td>
                                    <td></td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <select onChange={perPage} ref={pageCount}>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                        </select>
                    </div>
                    {teams?.data?.length &&
                        <Pagination>
                            {<Pagination.First onClick={() => teams?.first_page_url ? paginate(1) : ""} />}
                            {<Pagination.Prev onClick={() => teams?.prev_page_url ? paginate(teams?.current_page - 1) : ""} />}
                            <Pagination.Item>{teams?.from} - {teams?.to} of {teams?.total}</Pagination.Item>
                            {<Pagination.Next onClick={() => teams?.next_page_url ? paginate(teams?.current_page + 1) : ""} />}
                            {<Pagination.Last onClick={() => teams?.last_page_url ? paginate(teams?.last_page) : ""} />}
                        </Pagination>
                    }
                </div>
                <Modal show={!!modalDetails} onHide={handleTeamClose}>
                    <Modal.Header className="py-3 px-4" closeButton>
                        <Modal.Title className="h5">{modalDetails?.name ? "Update Team" : "Create Team"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form ref={teamForm} onSubmit={handleSubmit1(onTeamSubmit)}>
                            <div className="row"></div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Name</label>
                                    <Form.Control name="name" {...register1('name')} isInvalid={!!errors1.name} placeholder="Enter the name" />
                                    <Form.Control.Feedback type="invalid">{errors1.name?.message}</Form.Control.Feedback>
                                </div>
                                <hr />
                                <div className="mb-3">
                                    <label className="form-label">Status</label>
                                    <select className="form-select form-control" name="status" {...register1("status")}>
                                        <option value={true}>Active</option>
                                        <option value={false}>InActive</option>
                                    </select>
                                </div>
                                <hr />
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className="text-end">
                        <Button className="btn btn-light me-1" onClick={handleTeamClose}>
                            Cancel
                        </Button>
                        <Button className="btn btn-success" onClick={formTeamSubmit}>
                            {modalDetails?.name ? 'Update' : 'Create'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

        </>
    )
};
export default Teams;
import logoSm from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import layout1 from "../../assets/images/layouts/layout-1.png";
import layout2 from "../../assets/images/layouts/layout-2.png";
import { Dropdown } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../redux/auth/actions";
import { useNavigate } from "react-router";

const Header = () => {

    const [mode, setMode] = useState(localStorage.getItem("mode"));
    const { profileDetails } = useSelector(state => state.authReducer);
    const dark = useRef();
    const light = useRef();
    const dispatch = useDispatch();
    const history = useNavigate();

    const changeBarSize = () => {
        if (document.getElementsByTagName('body')[0]?.classList?.contains('sidebar-enable')) {
            document.getElementsByTagName('body')[0]?.classList.remove("vertical-collpsed");
            document.getElementsByTagName('body')[0]?.classList.remove("sidebar-enable");
        } else {
            document.getElementsByTagName('body')[0]?.classList.add("vertical-collpsed");
            document.getElementsByTagName('body')[0]?.classList.add("sidebar-enable");
        }
    }
    // vertical-collpsed right-bar-enabled
    const enableRightBar = () => {
        if (document.getElementsByTagName('body')[0]?.classList?.contains('right-bar-enabled')) {
            document.getElementsByTagName('body')[0]?.classList.remove("vertical-collpsed");
            document.getElementsByTagName('body')[0]?.classList.remove("right-bar-enabled");
        } else {
            document.getElementsByTagName('body')[0]?.classList.add("vertical-collpsed");
            document.getElementsByTagName('body')[0]?.classList.add("right-bar-enabled");
        }
    }

    if (localStorage.getItem('mode') === "dark") {
        document.getElementsByTagName('body')[0].setAttribute("data-sidebar", "dark")
        // document.getElementsByTagName('body')[0].style.backgroundColor = "#1d222e";
    } else {
        localStorage.setItem('mode', 'light');
        document.getElementsByTagName('body')[0].setAttribute("data-sidebar", "dark");
        // document.getElementsByTagName('body')[0].style.backgroundColor = "#f3f3f4";
    }

    const toggleMode = (check, md) => {
        if (md === "dark") {
            setMode("light");
            localStorage.setItem('mode', "light");
            light.current.checked = !check;
            // document.getElementsByTagName('body')[0].style.backgroundColor = "#1d222e";
        } else {
            setMode("dark");
            dark.current.checked = !check;
            localStorage.setItem('mode', "dark");
            // document.getElementsByTagName('body')[0].style.backgroundColor = "#f3f3f4";
        }
    }

    const handleProfile = (e) => {
        if (e === "2") {
            dispatch({ type: authActions.LOGOUT, history: history });
        } else {
            history('/profile');
        }
    }

    useEffect(() => {
        dispatch({ type: authActions.GET_PROFILE_DETAILS });
    }, []);

    return (
        <>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box text-center">
                            <a className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logoSm} alt="logo-sm-dark" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoDark} alt="logo-dark" height="35" />
                                </span>
                            </a>

                            <a className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logoSm} alt="logo-sm-light" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoLight} alt="logo-light" height="35" />
                                </span>
                            </a>
                        </div>

                        <button type="button" onClick={changeBarSize} className="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                            <i className="ri-menu-2-line align-middle"></i>
                        </button>
                    </div>

                    <div className="d-flex">
                        <div className="dropdown d-inline-block d-lg-none ms-2">
                            <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="ri-search-line"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">
                                <form className="p-3">
                                    <div className="mb-3 m-0">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search ..." />
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="submit"><i className="ri-search-line"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>


                        <div className="dropdown d-inline-block">
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">
                                <div className="p-3">

                                </div>
                                <div className="p-2 border-top">
                                    <div className="d-grid">
                                        <a className="btn btn-sm btn-link font-size-14 text-center" href="true"> <i className="mdi mdi-arrow-right-circle me-1"></i> View More.. </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dropdown d-inline-block">
                            <button type="button" onClick={enableRightBar} className="btn header-item noti-icon right-bar-toggle waves-effect">
                                <i className="mdi mdi-cog"></i>
                            </button>
                        </div>
                        <Dropdown className="dropdown d-inline-block user-dropdown" onSelect={handleProfile}>
                            <Dropdown.Toggle variant="white" split id="dropdown-split-basic" className="btn dropdown-toggle">
                                <a type="button" className="btn header-item waves-effect pt-0" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img className="rounded-circle header-profile-user" src={profileDetails?.image} alt={profileDetails?.name} />
                                    <span className="d-none d-xl-inline-block ms-1">{localStorage.getItem('ksp_user_name')}</span>
                                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                                </a>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align={'end'} >
                                <Dropdown.Item eventKey="1"><i className="ri-user-line align-middle me-1"></i> Profile</Dropdown.Item>
                                <div className="dropdown-divider"></div>
                                <Dropdown.Item className="text-danger" eventKey="2"><i className="ri-shut-down-line align-middle me-1 text-danger"></i> Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                </div>

            </header>

            <div className="right-bar">
                <div data-simplebar className="h-100">
                    <div className="rightbar-title d-flex align-items-center px-3 py-4">
                        <h5 className="m-0 me-2">Settings</h5>

                        <a onClick={enableRightBar} role="button" className="right-bar-toggle ms-auto">
                            <i className="mdi mdi-close noti-icon"></i>
                        </a>
                    </div>

                    <hr className="mt-0" />
                    <h6 className="text-center mb-0">Choose Theme Mode</h6>

                    <div className="p-4">
                        <div className="mb-2">
                            <img src={layout1} className="img-thumbnail" alt="layout-1" />
                        </div>

                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input theme-choice" type="checkbox" id="light-mode-switch" key='light' ref={light} onChange={(e) => toggleMode(e.target.checked, 'light')} defaultChecked={mode === "light"} />
                            <label className="form-check-label" htmlFor="light-mode-switch">Light Mode</label>
                        </div>

                        <div className="mb-2">
                            <img src={layout2} className="img-thumbnail" alt="layout-2" />
                        </div>
                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input theme-choice" type="checkbox" id="dark-mode-switch" key='dark' ref={dark} onChange={(e) => toggleMode(e.target.checked, 'dark')} defaultChecked={mode === "dark"} />
                            <label className="form-check-label" htmlFor="dark-mode-switch">Dark Mode</label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Header;
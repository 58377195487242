import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const PublicRoute = () => {

  return (
    <>
      {localStorage.getItem('ksp_token') ? <Navigate to='/timetracker' /> : <Outlet />}
    </>
  )
}

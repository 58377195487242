import actions from "./actions";

const initialState = {
    projectSettings: null,
    activeProjectSettings: [],
    filterParams: {
        status: true,
        name: ""
    },
    pagination: {
        entries: 5,
        currentPage: 1
    }
};
const projectSettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_PROJECT_SETTINGS:
            return {
                ...state,
                projectSettings: action.payload
            }
        case actions.SET_ACTIVE_PROJECT_SETTINGS:
            return {
                ...state,
                activeProjectSettings: action.payload
            }
        case actions.SET_FILTER_PARAMS:
            return {
                ...state,
                filterParams: action.payload
            }
        case actions.SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            }
        default:
            return state;
    }
};

export default projectSettingReducer;
import actions from "./actions";

const initialState = {
    categories: null,
    activeCategories: [],
    filterParams: {
        status: true,
        name: ""
    },
    pagination: {
        entries: 50,    
        currentPage: 1
    }
};
const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        case actions.SET_ACTIVE_CATEGORIES:
            return {
                ...state,
                activeCategories: action.payload
            }
        case actions.SET_FILTER_PARAMS:
            return {
                ...state,
                filterParams: action.payload
            }
        case actions.SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            }
        default:
            return state;
    }
};

export default categoryReducer;
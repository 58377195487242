import { useDispatch, useSelector } from "react-redux";
import reportActions from "../../redux/reports/actions";

const Filter = () => {

    const { initialSettings } = useSelector(state => state.reportReducer);
    const dispatch = useDispatch();

    const handleChange = (e, name) => {
        if (e.target.checked) {
            dispatch({
                type: reportActions.SET_INITIAL_SETTINGS, payload: [
                    ...initialSettings, name
                ]
            })
        } else {
            dispatch({
                type: reportActions.SET_INITIAL_SETTINGS, payload: initialSettings.filter(el => el !== name)
            })
        }
    }

    return (

        <div>
            <div>
                <p><input type="checkbox" onChange={(e) => handleChange(e, 'client')} defaultChecked={initialSettings.includes('client')} id="filter-client" /> <label htmlFor="filter-client">Client</label></p>
                <p><input type="checkbox" onChange={(e) => handleChange(e, 'description')} defaultChecked={initialSettings.includes('description')} id="filter-Description" /> <label htmlFor="filter-Description">Description</label></p>
                <p><input type="checkbox" onChange={(e) => handleChange(e, 'project')} defaultChecked={initialSettings.includes('project')} id="filter-Project" /> <label htmlFor="filter-Project">Project</label></p>
                <p><input type="checkbox" onChange={(e) => handleChange(e, 'status')} defaultChecked={initialSettings.includes('status')} id="filter-Status" /> <label htmlFor="filter-Status">Status</label></p>
                <p><input type="checkbox" onChange={(e) => handleChange(e, 'task')} defaultChecked={initialSettings.includes('task')} id="filter-Task" /> <label htmlFor="filter-Task">Task</label></p>
                <p><input type="checkbox" onChange={(e) => handleChange(e, 'team')} defaultChecked={initialSettings.includes('team')} id="filter-Team" /> <label htmlFor="filter-Team">Team</label></p>
                <p><input type="checkbox" onChange={(e) => handleChange(e, 'user')} defaultChecked={initialSettings.includes('user')} id="filter-User" /> <label htmlFor="filter-User">User</label></p>
            </div>
        </div>
    )

};

export default Filter;
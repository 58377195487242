const reportActions = {
    GET_REPORTS: 'GET_REPORTS',
    GET_EXCEL_REPORTS: 'GET_EXCEL_REPORTS',
    SET_REPORTS: 'SET_REPORTS',
    DUPLICATE_REPORT: 'DUPLICATE_REPORT',
    UPDATE_REPORT: 'UPDATE_REPORT',
    DELETE_REPORT: 'DELETE_REPORT',
    SET_FILTER_PARAMS: 'SET_FILTER_PARAMS_REPORTS',
    SET_INITIAL_SETTINGS: 'SET_INITIAL_SETTINGS_REPORTS',
    SET_PAGINATION: 'SET_PAGINATION_REPORTS',

    SET_TEAMS_SETTINGS: 'SET_TEAMS_SETTINGS_REPORTS',
    SET_TEAM_VALUE: 'SET_TEAMS_VALUE_REPORTS',

    SET_CLIENTS_SETTINGS: 'SET_CLIENTS_SETTINGS_REPORTS',
    SET_CLIENT_VALUE: 'SET_CLIENT_VALUE_REPORTS',

    SET_TASKS_SETTINGS: 'SET_TASKS_SETTINGS_REPORTS',
    SET_TASK_VALUE: 'SET_TASKS_VALUE_REPORTS',

    SET_PROJECTS_SETTINGS: 'SET_PROJECTS_SETTINGS_REPORTS',
    SET_PROJECT_VALUE: 'SET_PROJECTS_VALUE_REPORTS',

    SET_USERS_SETTINGS: 'SET_USERS_SETTINGS_REPORTS',
    SET_USER_VALUE: 'SET_USERS_VALUE_REPORTS',

    SET_DESCRIPTION_SETTINGS: 'SET_DESCRIPTION_REPORTS',
    SET_RESET: 'SET_RESET',
    
};

export default reportActions;
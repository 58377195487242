import actions from "./actions";

const initialState = {
    settings: null
};
const settingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_SETTINGS:
            return {
                ...state,
                settings: action.payload
            }
        default:
            return state;
    }
};

export default settingReducer;
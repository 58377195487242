import actions from "./actions";

const initialState = {
    clients: null,
    filterParams: {
        status: true,
        name: "",
    },
    pagination: {
        entries: 50,
        currentPage: 1
    }
};
const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_CLIENTS:
            return {
                ...state,
                clients: action.payload
            }
        case actions.SET_FILTER_PARAMS:
            return {
                ...state,
                filterParams: action.payload
            }
        case actions.SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            }
        default:
            return state;
    }
};

export default clientReducer;
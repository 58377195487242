
import React, { useRef } from 'react';
import { Divider, List, Popover, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { API_URL } from '../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import reportActions from '../../redux/reports/actions';
import { useCallback } from 'react';
import { debounce } from 'lodash';
const Project = () => {
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [data, setData] = useState([]);
    const projectRef = useRef([]);
    const { projectsSettings, projectValue } = useSelector(state => state.reportReducer);
    const dispatch = useDispatch();
    const loadMoreData = async () => {
        if (loading) {
            return;
        }
        setHasMore(true);
        setLoading(true);
        await axios.get(`${API_URL}/projects${data.length === 0 ? '' : `?page=${Math.ceil(data.length / 10)}`}`, {
            params: {
                status: projectsSettings?.status,
                entries: 10,
                name: projectValue
            }
        },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(el => {
                let dat = [...data, ...el.data.projects.data];
                let tempDat = [];
                dat.forEach(el => {
                    if (!tempDat.includes(el.id)) {
                        tempDat.push(el);
                    }
                });
                dat = tempDat;
                if (dat.length === el.data.projects.total || el.data.projects.total === 0 || el.data.projects.to === null || el.data.projects.to === el.data.projects.total) {
                    setHasMore(false);
                }
                if (projectsSettings.selectAll) {
                    dispatch({
                        type: reportActions.SET_PROJECTS_SETTINGS,
                        payload: {
                            ...projectsSettings,
                            selectedIds: [...projectsSettings.selectedIds, ...el.data.teams.data.map(e => e.id)],
                            unSelectedIds: []
                        }
                    })
                }
                setData(dat);
                setLoading(false);
            }).catch(err => {
                setData([]);
                setHasMore(false);
                setLoading(false);
            });
    };

    const onScroll = (e) => {
        if (e?.currentTarget?.scrollHeight - e?.currentTarget?.scrollTop >= 400) {
            loadMoreData();
        }
    };


    const handleProjectStatus = (e) => {
        setData([]);
        dispatch({
            type: reportActions.SET_PROJECTS_SETTINGS,
            payload: {
                ...projectsSettings,
                status: e.target.value
            }
        })
    }

    const handleInputValue = (e) => {
        setData([]);
        setLoading(false);
        setHasMore(false);
        dispatch({
            type: reportActions.SET_PROJECT_VALUE,
            payload: e.target.value

        });
    }

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            dispatch({
                type: reportActions.SET_PROJECTS_SETTINGS,
                payload: {
                    ...projectsSettings,
                    selectAll: e.target.checked,
                    selectedIds: data.map(el => el.id),
                    unSelectedIds: []
                }
            })
        } else {
            dispatch({
                type: reportActions.SET_PROJECTS_SETTINGS,
                payload: {
                    ...projectsSettings,
                    selectAll: false,
                    selectedIds: [],
                    unSelectedIds: []
                }
            })
        }
    }

    const handleSelectedIds = (e, id) => {
        if (e.target.checked) {
            dispatch({
                type: reportActions.SET_PROJECTS_SETTINGS,
                payload: {
                    ...projectsSettings,
                    selectedIds: removeDuplicates(data).filter(el => projectRef.current[el.id].checked).map(el1 => el1.id),
                    unSelectedIds: removeDuplicates(data).filter(el => !projectRef.current[el.id].checked).map(el1 => el1.id)
                }
            })
        } else {
            dispatch({
                type: reportActions.SET_PROJECTS_SETTINGS,
                payload: {
                    ...projectsSettings,
                    selectedIds: removeDuplicates(data).filter(el => projectRef.current[el.id].checked).map(el1 => el1.id),
                    unSelectedIds: removeDuplicates(data).filter(el => !projectRef.current[el.id].checked).map(el1 => el1.id)
                }
            })
        }
    }

    const removeDuplicates = (temp) => {
        let temp1 = [];
        temp.forEach(el => {
            if (!temp1.find(e => e.id === el.id)) {
                temp1.push(el);
            }
        });
        return temp1;
    }

    const handleDebugger = useCallback(
        debounce(handleInputValue, 400)
        , []);


    useEffect(() => {
        loadMoreData();
    }, [projectsSettings?.selectAll, projectsSettings?.status, projectValue]);

    return (
        <div style={{ width: '300px' }}>
            <div>
                <input className='form-control my-2' onChange={handleDebugger} defaultValue={projectsSettings?.inputValue} />
            </div>
            <div className='d-flex justify-content-between'>
                <p>Show</p>
                <select onChange={handleProjectStatus} defaultValue={projectsSettings?.status}>
                    <option value="all">Active & Archived</option>
                    <option value={true}>Active</option>
                    <option value={false}>InActive</option>
                </select>
            </div>
            <div>
                <p>
                    <input type="checkbox" id="infinity-scroll-select-all-project" defaultChecked={projectsSettings?.selectAll} onChange={handleSelectAll} />
                    <label htmlFor={'infinity-scroll-select-all-project'}> Select All</label>
                </p>
            </div>
            <Divider />
            <div
                id="scrollableDiv"
                style={{
                    height: 400,
                    overflow: 'auto',
                    padding: '0 16px',
                    border: '1px solid rgba(140, 140, 140, 0.35)',
                }}
                onScroll={onScroll}
            >
                <InfiniteScroll
                    dataLength={data.length}
                    next={loadMoreData}
                    loader={
                        <Skeleton
                            avatar
                            paragraph={{
                                rows: 1,
                            }}
                            active
                        />
                    }
                    hasMore={hasMore}
                    endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={removeDuplicates(data)}
                        renderItem={(item) => (
                            <List.Item key={item.id + 'project-infinity-scroll'}>
                                <p>
                                    <span> <input id={item.id + 'project-infinity-scroll-id'} key={projectsSettings.selectedIds.includes(item.id)} ref={el => projectRef.current[item.id] = el} defaultChecked={projectsSettings.selectedIds.includes(item.id)} onChange={(e) => handleSelectedIds(e, item.id)} type="checkbox" />     </span>
                                    <label htmlFor={item.id + 'project-infinity-scroll-id'}> {item.title}</label>
                                </p>
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>
        </div >
    );
};
export default Project;
import DarkLogo from '../../assets/images/logo-dark.png';
import LightLogo from '../../assets/images/logo-light.png';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import authActions from '../../redux/auth/actions';
import { useLocation, useNavigate } from 'react-router';
import { Form } from 'react-bootstrap';

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required('password is required'),
    password_confirmation: Yup.string().required('Password is mendatory')
        .oneOf([Yup.ref('password')], 'Passwords does not match'),
});

const ConfirmPassword = () => {

    const { register, handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const dispatch = useDispatch();
    const history = useNavigate();
    const { state } = useLocation();

    const onSubmit = (data) => {
        dispatch({
            type: authActions.RESET_PASSWORD, payload: {
                email: state.email,
                password: data.password,
                password_confirmation: data.password_confirmation,
                otp: state.otp,
            },
            history: history
        });
    }

    return (
        <div className="bg-pattern">
            <div className="bg-overlay"></div>
            <div className="account-pages my-5 pt-5">
                <div className="container">

                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-6 col-md-8">
                            <div className="card">
                                <div className="card-body p-4">
                                    <div className="">
                                        <div className="text-center">
                                            <a href="index.html" className="">
                                                <img src={DarkLogo} alt="" height="24"
                                                    className="auth-logo logo-dark mx-auto" />
                                                <img src={LightLogo} alt="" height="24"
                                                    className="auth-logo logo-light mx-auto" />
                                            </a>
                                        </div>
                                        <p className="mb-5 text-center">Reset Password </p>
                                        <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="password">New Password</label>
                                                        <Form.Control type="password" placeholder="Enter new password" name="password" {...register('password')} />
                                                        <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="password_confirmation">Password Confirmation</label>
                                                        <Form.Control type="password" placeholder="Enter the password again" name="password" {...register('password_confirmation')} />
                                                        <Form.Control.Feedback type="invalid">{errors.password_confirmation?.message}</Form.Control.Feedback>
                                                    </div>
                                                    <div className="d-grid mt-4">
                                                        <button className="btn btn-primary waves-effect waves-light"
                                                            type="submit">Reset Password
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmPassword;
import { Collapse } from "antd";
import { Divider, Skeleton } from 'antd';
import axios from "axios";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { API_URL } from "../../utils/constant";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRef } from "react";

const Content = (props) => {

    const [details, setDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const inputValue = useRef();

    const { Panel } = Collapse;

    const loadMoreData = async () => {
        if (loading) {
            return;
        }
        setHasMore(true);
        setLoading(true);
        await axios.get(`${API_URL}/clients-search1/${inputValue.current.value}/${details.length === 0 ? '' : `?page=${Math.ceil(details.length / 10)}`}`).then(el => {
            let dat = [...details, ...el.data.clients.data];
            let tempDat = [];
            dat.forEach(el => {
                if (!tempDat.includes(el.id)) {
                    tempDat.push(el);
                }
            });
            dat = tempDat;
            if (details.length === el.data.clients.total || el.data.clients.total === 0 || el.data.clients.to === null || el.data.clients.to === el.data.clients.total) {
                setHasMore(false);
            }
            setDetails(dat);
            setLoading(false);
        }).catch(err => {
            setDetails([]);
            setHasMore(false);
            setLoading(false);
        });
    }

    const debouncedChangeHandler = useCallback(
        debounce(loadMoreData, 700)
        , []);

    const passTaskId = (el, title, code, proj, client) => {
        setDetails([]);
        setLoading(false);
        setHasMore(false);
        inputValue.current.value = "";
        el['projectName'] = title;
        el['clientName'] = code;
        proj['client'] = {
            code: client.code,
            name: client.name
        }
        props.hide(el, proj);
    }

    useEffect(() => {
        setDetails([]);
        setLoading(false);
        setHasMore(false);
        inputValue.current.value = "";
    }, []);

    return (
        <div style={{ width: '500px', maxHeight: '500px', overflow: 'auto' }} id="scrollableDiv1">
            <input className="form-control" ref={inputValue} placeholder="Find task @project or client" onInput={debouncedChangeHandler} />
            {details.length > 0 ?
                <InfiniteScroll
                    dataLength={details.length}
                    next={loadMoreData}
                    loader={
                        <Skeleton
                            avatar
                            paragraph={{
                                rows: 1,
                            }}
                            active
                        />
                    }
                    hasMore={hasMore}
                    endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                    scrollableTarget="scrollableDiv1"
                >
                    <Collapse >
                        {details.map((el, index) =>

                            <Collapse defaultActiveKey={details.map((el, ind) => 'clients-tasks' + ind)}>
                                <Panel header={<p className="d-flex justify-content-between"><span>{el.code}-{el.name}</span> <span>{el.projects.length + 'Projects'}</span></p>} key={'clients-tasks' + index}>
                                    {el.projects.map((el1, ind) =>

                                        <Collapse defaultActiveKey={inputValue?.current?.value?.split('').includes('@') ? el.projects.map((el, ind) => 'projects-tasks' + ind) : []}>
                                            <Panel header={<p className="d-flex justify-content-between"><span>{el1.title}</span> <span>{el1.tasks.length + 'Tasks'}</span></p>} key={'projects-tasks' + ind}>
                                                {el1.tasks.map((el2, ind1) =>
                                                    // <Panel header={el2.name} key={'tasks' + ind1}>
                                                    <p className="cursor-pointer" onClick={() => passTaskId(el2, el1.title, el.code + '-' + el.name, el1, el)} key={'tasks-tasks' + ind} >{el2.name}</p>
                                                    // </Panel>
                                                )}
                                            </Panel>
                                        </Collapse>
                                    )}
                                </Panel>
                            </Collapse>
                        )}

                    </Collapse>
                </InfiniteScroll>
                : <></>}

        </div>
    )
};

export default Content;